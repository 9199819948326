import { UserDTO } from "@app/models/dtos/UserDto";
import { BASE_API_URL } from "../http";
import { IProfile } from "@app/store/redux/profile";
import profileImgMock from "@assets/imgs/avatar/profileImage.png";

export const getUserId = (user: UserDTO) => (user ? user._id : null);
export const getUserFullName = (user: UserDTO) =>
  user ? `${user.name} ${user.lastname}` : null;
export const getUsername = (user: UserDTO) =>
  user && user.username ? `@${user.username.toLowerCase()}` : null;
export const getUserEmail = (user: UserDTO) =>
  user && user.email ? user.email : null;
export const getUserPhone = (user: UserDTO) =>
  user && user.phone ? user.phone : null;
export const getUserAvatar = (user: UserDTO) =>
  user?.avatarFile
    ? `${BASE_API_URL}images/${user.avatarFile}`
    : profileImgMock;
export const getUserLink = (user: UserDTO) =>
  user ? `profile/${user._id}` : null;
export const getUserCountryCode = (user: any) =>
  user && user?.city?.countryCode ? user.city.countryCode : undefined;
export const getUserCity = (user: UserDTO) =>
  user && user?.city?.name ? user.city.name.toUpperCase() : undefined;

export const getUserFollowerCount = (user: UserDTO) =>
  user && user.followers ? user.followers : 0;
export const getUserFollowingCount = (user: UserDTO) =>
  user && user.following ? user.following : 0;

export const getUserEventsCount = (events: any) => (events ? events.length : 0);

export const convertPhoneFormat = (phone: string) =>
  phone
    .replace(/\D+/g, "")
    .replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, "+$1 ($2) $3-$4-$5");
