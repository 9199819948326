import React from "react";
import { useTranslation } from "react-i18next";
import { Form, Input } from "antd";

type ChannelLinkFieldProps = {
  initValue?: string;
  required: boolean;
};

const ChannelLinkField: React.FC<ChannelLinkFieldProps> = ({ initValue, required }) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      name="channelLink"
      label={"Channel Link"}
      initialValue={initValue}
      rules={[
        {
          required: required,
          message: t("common.validation.required") as string,
        },
      ]}
    >
      <Input placeholder={"Channel Link"} />
    </Form.Item>
  );
};
export default ChannelLinkField;
