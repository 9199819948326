import React, { lazy } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import AuthLayout from "../../pages/authentication/Index";

const ForgotPass = lazy(
  () => import("../../pages/authentication/overview/ForgotPassword")
);
const ConfirmEmail = lazy(
  () => import("../../pages/authentication/overview/ConfirmEmail")
);
const ResetPassword = lazy(
  () => import("../../pages/authentication/overview/ResetPassword")
);
const Login = lazy(() => import("../../pages/authentication/overview/SignIn"));
const SignUp = lazy(() => import("../../pages/authentication/overview/Signup"));

const path = "auth";

const FrontendRoutes = React.memo(() => {
  return (
    <AuthLayout>
      <Routes>
        <Route index element={<Login path={path} />} />
        <Route path="forgotPassword" element={<ForgotPass path={path} />} />
        <Route path="confirmEmail" element={<ConfirmEmail path={path} />} />
        <Route
          path="resetPassword/:link"
          element={<ResetPassword path={path} />}
        />
        <Route path="register" element={<SignUp path={path} />} />
        <Route path="*" element={<Navigate to="/auth" replace />} />
      </Routes>
    </AuthLayout>
  );
});

export default FrontendRoutes;
