import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { UilAngleLeftB, UilAngleRightB } from '@iconscout/react-unicons';
import { CATEGORIES, Category, ECategories } from '@app/helpers/categories';
import classNames from 'classnames';
import { Divider } from 'antd';

import "./carousel.scss";

type CarouselProps = {
    activeCategory?: ECategories;
    onSetCategory: (category: Category) => void;
};

const UiCarousel: React.FC<CarouselProps> = ({ activeCategory, onSetCategory }) => {

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 3,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 320,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2
                }
            }
        ]
    };

    const renderCategory = (category: Category) => (
        <div onClick={() => onSetCategory(category)} key={category.value} className={classNames('slider-category', { 'active': activeCategory === category.value })}>
            {category.icon}
            <span className='slider-category__text'>{category.text}</span>
        </div>
    )

    const renderReset = (category: Category) => (
        <div onClick={() => onSetCategory(category)} key={category.value} className='reset'>
            <div className='slider-category'>
                {category.icon}
                <span className='slider-category__text'>{category.text}</span>
            </div>
            <Divider className="reset-divider" type='vertical' />
        </div>
    )

    return (
        <div className="slider-container">
            <Slider {...settings}>
                {CATEGORIES.map((category) => category.value === ECategories.RESET ? renderReset(category) : renderCategory(category))}
            </Slider>
        </div>
    );
}
export default UiCarousel;

const NextArrow = (props: any) => {
    const { onClick } = props;
    return (
        <div onClick={onClick} className='arrow-btn'>
            <UilAngleRightB size={20} />
        </div>
    );
}

const PrevArrow = (props: any) => {
    const { onClick } = props;
    return (
        <div onClick={onClick} className='arrow-btn'>
            <UilAngleLeftB size={20} />
        </div>
    );
}