import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Chat from "@app/pages/chat/ChatApp";
import Layout from "@app/layout/layout";

const ChatComponent = React.memo(() => {
  const { pathname } = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Chat />} />
        {/* <Route path="/*" element={<Navigate to="chat" replace />} /> */}
      </Routes>
    </Layout>
  );
});

export default ChatComponent;
