import {
  removeToken,
  removeLocalUserId,
  setToken,
  setLocalUserId,
} from "@app/helpers/jwtHelper";
import { IUser } from "@app/models/User";
import {
  AuthUserDTO,
  ParamsVerificateEmail,
} from "@app/services/auth/AuthResponse";
import AuthService from "@app/services/auth/AuthService";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notification } from "antd";

export type LoginParams = {
  email: string;
  password: string;
};

export type RegistrationParams = {
  name: string;
  lastname: string;
  email: string;
  password: string;
};

export interface AuthState {
  isAuth: boolean;
  loading: boolean;
  error: null | any;
  user: AuthUserDTO;
  form?: any;
}

const initialState: AuthState = {
  user: {} as IUser,
  isAuth: false,
  loading: false,
  error: null,
};

export const loginAsync = createAsyncThunk(
  "auth/login",
  async ({ email, password }: LoginParams, { rejectWithValue }) => {
    try {
      const { data } = await AuthService.login(email, password);
      notification.success({ message: "Вы успешно авторизовались!" });
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const loginGoogleAsync = createAsyncThunk(
  "auth/loginGoogle",
  async (credential: any, { rejectWithValue }) => {
    try {
      const { data } = await AuthService.loginByGoogle(credential);
      notification.success({ message: "Вы успешно авторизовались!" });
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const registrationAsync = createAsyncThunk(
  "auth/registration",
  async (params: RegistrationParams, { rejectWithValue }) => {
    try {
      const { data } = await AuthService.registration(params);
      notification.success({ message: "Пожалуйста, подтвердите почту!" });
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const activateEmailAsync = createAsyncThunk(
  "auth/activateEmail",
  async (email: string, { rejectWithValue }) => {
    try {
      const { data } = await AuthService.activateEmail(email);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const newPasswordAsync = createAsyncThunk(
  "auth/newPassword",
  async ({ password, activatedLink }: any, { rejectWithValue }) => {
    try {
      const { data } = await AuthService.newPassword({
        password,
        activatedLink,
      });
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const verificateEmailAsync = createAsyncThunk(
  "auth/verificateEmail",
  async ({ verificationCode, email }: any, { rejectWithValue }) => {
    try {
      const { data } = await AuthService.verificateEmail({
        verificationCode,
        email,
      });
      notification.success({ message: "Почта подтверждена!" });
      return data;
    } catch (err) {
      notification.error({ message: err.response.data.message });
      return rejectWithValue(err.response.data);
    }
  }
);

export const resetPasswordAsync = createAsyncThunk(
  "auth/resetPassword",
  async (
    { verificationCode, email }: ParamsVerificateEmail,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await AuthService.resetPassword({
        verificationCode,
        email,
      });
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const changePasswordAsync = createAsyncThunk(
  "auth/changePassword",
  async ({ oldPassword, password }: any, { rejectWithValue }) => {
    try {
      const { data } = await AuthService.changePassword(oldPassword, password);
      notification.success({ message: "Password changed" });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      notification.error({ message: err.response.data.message });
      return rejectWithValue(err.response.data);
    }
  }
);

export const logoutAsync = createAsyncThunk("auth/logout", async () => {
  try {
    const request = await AuthService.logout();
    notification.success({ message: "Вы успешно вышли из системы" });
    return request;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
  }
});

export const checkAuthAsync = createAsyncThunk("auth/checkAuth", async () => {
  const { data } = await AuthService.checkAuth();
  return data;
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<boolean>) => {
      state.isAuth = action.payload;
    },
    setUser(state, action: PayloadAction<IUser>) {
      state.user = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    logout: (state) => {
      state.isAuth = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        setToken(payload.accessToken);
        setLocalUserId(payload.user.id);
        state.isAuth = true;
        state.user = payload.user;
      })
      .addCase(loginAsync.rejected, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(loginGoogleAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        setToken(payload.accessToken);
        setLocalUserId(payload.user.id);
        state.isAuth = true;
        state.user = payload.user;
      })
      .addCase(loginGoogleAsync.rejected, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(registrationAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(registrationAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        setToken(payload.accessToken);
        setLocalUserId(payload.user.id);
        state.isAuth = true;
        state.user = payload.user;
      })
      .addCase(registrationAsync.rejected, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(logoutAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(logoutAsync.fulfilled, (state) => {
        removeToken();
        removeLocalUserId();
        state.loading = false;
        state.isAuth = false;
        state.user = {} as IUser;
      })
      .addCase(logoutAsync.rejected, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(checkAuthAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(checkAuthAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        setToken(payload.accessToken);
        setLocalUserId(payload.user.id);
        state.isAuth = true;
        state.user = payload.user;
      })
      .addCase(checkAuthAsync.rejected, (state, { payload }) => {
        state.loading = false;
        state.isAuth = false;
        removeToken();
      })
      .addCase(changePasswordAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(changePasswordAsync.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(changePasswordAsync.rejected, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(resetPasswordAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(resetPasswordAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.isAuth = true;
      })
      .addCase(resetPasswordAsync.rejected, (state, { payload }) => {
        state.loading = false;
        state.user = {} as IUser;
      })
      .addCase(verificateEmailAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(verificateEmailAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.isAuth = true;
        state.user = payload.user;
      })
      .addCase(verificateEmailAsync.rejected, (state, { payload }) => {
        state.loading = false;
        state.user = {} as IUser;
      });
  },
});

export const { logout, setAuth, setUser, setLoading } = authSlice.actions;

export default authSlice.reducer;
