import React, { useRef, useState } from "react";
import "./layout.scss";
import Header from "./header/header";
import Footer from "./footer/footer";
import { useAppDispatch, useAppSelector } from "@app/store/redux/store";
import { fetchAvatarAsync, fetchProfileAsync } from "@app/store/redux/profile";
import CreateEventModal from "@app/components/modals/CreateEventModal";
import CreatePlaceModal from "@app/components/modals/CreatePlaceModal";
import modals from "@app/store/mobx/modals";
import { event } from "@app/store/mobx";
import { observer } from "mobx-react-lite";
import SideBar from "./sidebar/sideBar";
import { Drawer } from "antd";

type LayoutProps = {
  children: React.ReactNode;
};

const Layout: React.FC<LayoutProps> = observer(({ children }) => {
  const dispatch = useAppDispatch();

  const { isLoggedIn } = useAppSelector((state) => {
    return {
      isLoggedIn: state.auth.isAuth,
    };
  });

  React.useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchProfileAsync()).then(() => dispatch(fetchAvatarAsync()));
    }
  }, [isLoggedIn]);

  return (
    <div className="layout-light main">
      <div className="header-wrapper">
        <Header />
      </div>

      <main className="main-content">
        <Drawer
          className="sidebar-wrapper"
          closable={true}
          onClose={modals.toggleSidebar}
          open={modals.sideBarDrawer}
          placement="left"
          width={280}
        >
          <SideBar toggleSidebar={modals.toggleSidebar} />
        </Drawer>
        <div className="contents">{children}</div>
        <footer className="footer-wrapper">
          <Footer />
        </footer>
      </main>
      <CreateEventModal
        visible={modals.createEvent}
        onCancel={modals.onCancel}
        onUploadFiles={event.uploadFiles}
        onSubmit={event.createEvent}
      />
      <CreatePlaceModal
        visible={modals.createPlace}
        onCancel={() => modals.onCancel('createPlace')}
        onUploadFiles={event.uploadFiles}
        onSubmit={event.createPlace}
      />
    </div>
  );
});
export default Layout;
