import MainPage from "@app/pages/MainPage";
import Layout from "@app/layout/layout";
import { Spin } from "antd";
import React, { lazy, Suspense, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

const Profile = lazy(() => import("@app/pages/profile/profile/profile"));
const EventPage = lazy(() => import("@app/pages/EventPage/EventPage"));
const NotFound = lazy(() => import("@app/pages/404/404"));

const Public = React.memo(() => {
  const { pathname } = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Layout>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Routes>
          <Route path="/profile/:id/*" element={<Profile />} />
          <Route path="/event/:id/*" element={<EventPage />} />
          <Route index path="/" element={<MainPage />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </Layout>
  );
});

export default Public;
