import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Input, Select, Alert, FormInstance } from "antd";

type EventTypeFieldProps = {
  initValue?: string;
  disabled?: boolean;
  form?: FormInstance;
};

export enum EventType {
  EVENT = "EVENT",
  PLACE = "PLACE",
}

const EventTypeField: React.FC<EventTypeFieldProps> = ({
  initValue,
  disabled,
  form,
}) => {
  const { t } = useTranslation();
  const [fieldValue, setFieldValue] = useState<EventType | undefined>(
    initValue as EventType
  );

  const handleChange = (value: EventType) => {
    setFieldValue(value);
    form?.setFieldValue("eventType", value);
  };

  return (
    <Form.Item
      name="eventType"
      label={"Event Type"}
      initialValue={initValue ?? EventType.PLACE}
      rules={[
        {
          required: true,
          message: t("common.validation.required") as string,
        },
      ]}
    >
      <Select
        showSearch
        placeholder="Select event type"
        optionFilterProp="children"
        onChange={handleChange}
        value={fieldValue}
        disabled={disabled}
        options={[
          {
            value: EventType.EVENT,
            label: "Event",
          },
          {
            value: EventType.PLACE,
            label: "Place",
          },
        ]}
      />
    </Form.Item>
  );
};
export default EventTypeField;
