import React from "react";
import { Link } from "react-router-dom";
import "./footer.scss";

type FooterProps = {};

const Footer: React.FC<FooterProps> = () => {
  return (
    <div className="footer">
      <div className="footer-left">
        <div className="footer-copyright">
          <span>© 2024</span>
          <Link
            className="footer-copyright__link"
            to="https://t.me/pllacesupport"
          >
            Support
          </Link>
        </div>
      </div>
      <div className="footer-right">
        <div className="footer-menu">
          <ul>
            <li>
              <a href="#">v1.2</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Footer;
