import React, { useState, useEffect, Fragment, FC } from "react";
import { Drawer } from "antd";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import { Scrollbars } from "react-custom-scrollbars";
import Picker from "emoji-picker-react";
import {
  SingleChatWrapper,
  MessageList,
  Footer,
  BackShadowEmoji,
} from "../style";
import Heading from "@app/components/heading/heading";
import { Button } from "@app/components/UIElements/buttons/buttons";
import { Cards } from "@app/components/cards/frame/cards-frame";
import { UilMessage, UilSmile } from "@iconscout/react-unicons";
import { ChatContext } from "@app/store/provider";
import { Empty } from "antd";
import * as _ from "lodash";
import { socket } from "@app/helpers/http";
import dotetGif from "@assets/dots.gif";

import {
  getUserAvatar,
  getUserFullName,
  getUserLink,
} from "@app/helpers/utils/user";
import { observer } from "mobx-react-lite";
import modals from "@app/store/mobx/modals";
import { useTranslation } from "react-i18next";
import { BadgeParticipants } from "@app/components/post/participants/participants";
import { PushNotificationGroupType } from "@app/services/push/PushService";

interface Props {
  match?: any;
  dashboard?: boolean;
  chatIdProps?: string;
}

const SingleChat: FC<Props> = ({ match, dashboard, chatIdProps }) => {
  const { t } = useTranslation();
  const {
    fetchChat,
    getChat,
    fetchChatMessages,
    getMessages,
    sendMessage,
    getMyId,
    markAllMessagesAsRead,
    getChatMembers,
    getPostTitle,
    getPostId,
    cleanMessages,
    sendPushNotificationFromChat,
  } = React.useContext(ChatContext);

  const [typing, setTyping] = React.useState<boolean>(false);

  const messages = getMessages();
  const params = useParams();
  const { chatId } = params;

  const bottomRef = React.useRef<any>(null);
  const me = getMyId();

  const chatId$ = chatIdProps || chatId;

  if (!chatId$) {
    return null;
  }

  const chatMembers = getChatMembers(chatId$);

  const scrollToBottom = () => {
    bottomRef.current.scrollToBottom();
  };

  const [chatMessage, setChatMessage] = useState<string>("");

  const handleInputChange = (e: any) => {
    setChatMessage(e.target.value);
    socket.emit("typing", chatId$);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (chatMessage.trim() !== "") {
      sendMessage(chatMessage, chatId$)
        .then(() => socket.emit("stop typing", chatId$))
        .then(() => socket.emit("new message", chatId$))
        .then(() => socket.emit("new message from group", getChat()))
        .then(() => fetchChatMessages(chatId$))
        .then(() => scrollToBottom());
      setChatMessage("");

      if (chatMessage.includes("@push")) {
        const payload = {
          type: PushNotificationGroupType.GROUP,
          fromName: getChat()?.post?.title,
          message: chatMessage.replace("@push", ""),
          userIds: getChat()?.post?.participants,
          chatId: chatId$,
        };
        sendPushNotificationFromChat(payload);
      }
    }
  };

  let showToday = 0;

  React.useLayoutEffect(() => {
    scrollToBottom();
  });

  React.useEffect(() => {
    fetchChat(chatId$);
    fetchChatMessages(chatId$);
    markAllMessagesAsRead(chatId$);
  }, [chatId$]);

  useEffect(() => {
    const timer = setTimeout(() => socket.emit("stop typing", chatId$), 2000);
    return () => clearTimeout(timer);
  }, [chatMessage]);

  React.useEffect(() => {
    socket.emit("join room", chatId$);
    socket.on("typing", () => setTyping(true));
    socket.on("stop typing", () => setTyping(false));
    socket.on("new message", () => fetchChatMessages(chatId$));
  }, [chatId$]);

  React.useEffect(() => {
    return () => {
      socket.emit("leave room", chatId$);
      cleanMessages();
    };
  }, []);

  return (
    <SingleChatWrapper className={dashboard ? "pllace-chat-home" : ""}>
      <Cards
        title={
          <div className="group-chat-header d-flex">
            <Heading as="h5">{<>{getPostTitle()}</>}</Heading>
            <div className="members">
              <BadgeParticipants
                count={chatMembers.length}
                eventId={getPostId()}
              />
              {/* {chatMembers.map((member, index) => {
                const userImg = getUserAvatar(member);
                return (
                  <Link key={member._id} to={`/${getUserLink(member)}`}>
                    <img src={userImg} alt="" />
                  </Link>
                );
              })} */}
            </div>
          </div>
        }
      >
        <ul className="atbd-chatbox">
          <Scrollbars
            className="custom-scrollbar"
            autoHide
            ref={bottomRef}
            autoHideTimeout={500}
            autoHideDuration={200}
          >
            {!_.isEmpty(messages) ? (
              messages.map((mes: any, index: number) => {
                const { sender } = mes;
                const isToday = moment().isSame(mes.createdAt, "day");

                isToday ? showToday++ : null;

                return (
                  <Fragment key={index}>
                    {isToday && showToday == 1 && (
                      <p className="time-connector text-center text-capitalize">
                        <span>today</span>
                      </p>
                    )}
                    <li
                      className="atbd-chatbox__single"
                      key={index}
                      style={{ overflow: "hidden" }}
                    >
                      <div className={sender._id !== me ? "left" : "right"}>
                        <div className="atbd-chatbox__content">
                          <Heading as="h5" className="atbd-chatbox__name">
                            {sender._id !== me && getUserFullName(sender)}
                            <span>
                              {isToday
                                ? moment(mes.createdAt).format("HH:mm")
                                : moment(mes.createdAt).format("HH:mm LL")}
                            </span>
                          </Heading>

                          {sender._id !== me ? (
                            <div className="atbd-chatbox__contentInner d-flex">
                              <div className="atbd-chatbox__message">
                                <MessageList className="message-box">
                                  {mes.content}
                                </MessageList>
                              </div>
                            </div>
                          ) : (
                            <div className="atbd-chatbox__contentInner content-end">
                              <div className="atbd-chatbox__message">
                                <MessageList className="message-box">
                                  {mes.content}
                                </MessageList>
                                <div className="message-seen text-right"></div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </li>
                  </Fragment>
                );
              })
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
            {typing && (
              <div className="typingDots">
                <img src={dotetGif} alt="Typing dots" />
              </div>
            )}
          </Scrollbars>
        </ul>

        <Footer>
          <form onSubmit={handleSubmit}>
            <div className={`chatbox-reply-form d-flex`}>
              <div className="chatbox-reply-input">
                <input
                  onChange={handleInputChange}
                  placeholder={t("chat.typeYourMessage") as string}
                  name="chat"
                  id="chat"
                  style={{ width: "100%" }}
                  value={chatMessage}
                />
              </div>
              <div className="chatbox-reply-action d-flex">
                <Button
                  onClick={handleSubmit}
                  type="primary"
                  className="btn-send"
                >
                  <UilMessage />
                </Button>
              </div>
            </div>
          </form>
        </Footer>
      </Cards>
    </SingleChatWrapper>
  );
};

export default SingleChat;

export const SingleGroupChat$: FC<Props> = observer((props) => {
  const { t } = useTranslation();
  const screenSize = document.documentElement.scrollWidth;
  const drawerSize = screenSize > 575 ? "500px" : "100%";

  const { chatId } = useParams();

  useEffect(() => {
    if (chatId) {
      modals.onVisibleChatDrawer();
    }
  }, [chatId]);

  return (
    <Drawer
      title={t("chat.chat")}
      className="drawer-chat-wrapper group"
      width={drawerSize}
      placement="right"
      onClose={modals.onCloseChatDrawer}
      open={modals.chatDrawer}
      style={{ zIndex: 999 }}
    >
      <div className="drawer-chat">
        <SingleChat {...props} />
      </div>
    </Drawer>
  );
});
