import React, { useState } from "react";
import { Form, Col, Row, notification } from "antd";
import { Modal } from "../UIElements/modals/modals";
import { Button } from "../UIElements/buttons/buttons";
import { BasicFormWrapper } from "@app/pages/styled";
import { Cards } from "../cards/frame/cards-frame";
import _, { toNumber } from "lodash";
import { LatLngTuple } from "leaflet";
import { profile } from "@app/store/mobx";
import { toJS } from "mobx";
// import { ICreateEditPlace } from "@app/models/Place";
import { PostDTO } from "@app/models/dtos/PostDto";
import { useTranslation } from "react-i18next";
import {
  DescriptionField,
  TitleField,
  AddressField,
  MapField,
  ImagesField,
  CategoriesField,
} from "../formsFields";
import EventTypeField from "../formsFields/eventTypeField";
import ChannelLinkField from "../formsFields/channelLinkField";
import { ICreateEditPlace } from "@app/models/Event";
import { AccessType } from "../formsFields/accessTypeField";

type CreatePlaceModalProps = {
  visible: boolean;
  onCancel: () => void;
  onUploadFiles: any;
  onSubmit: (values: FormData) => Promise<PostDTO>;
};

const CreatePlaceModal: React.FC<CreatePlaceModalProps> = ({
  visible,
  onCancel,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const myProfile = toJS(profile.myProfile);
  const myCoordinates: LatLngTuple =
    myProfile && myProfile.city
      ? [toNumber(myProfile.city.latitude), toNumber(myProfile.city.longitude)]
      : [0, 0];
  const [markerFromAddress, setMarkerFromAddress] = useState<
    LatLngTuple | undefined
  >();

  const [form] = Form.useForm();

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const handleSubmit = async (values: ICreateEditPlace) => {
    const formData = new FormData();

    formData.append("title", values.title);
    formData.append("description", values.description);
    //массив файлов
    values.imageUploades.forEach((file: any, i:any) => {
      formData.append(`files`, file.originFileObj);
    });

    formData.append("address", values.address);
    formData.append("coordinates", JSON.stringify(values.coordinates));
    formData.append("channelLink", values.channelLink);
    formData.append("eventType", values.eventType);
    formData.append("accessType", AccessType.COMMON);
    if(!_.isEmpty(values.categories)){
      formData.append("categories", JSON.stringify(values.categories));
    }

    await onSubmit(formData);
    onCancel();
    notification.success({ message: 'Place was added' });
  };

  return (
    <Modal
      type="primary"
      title={"Adding new place"}
      visible={visible}
      onOk={onSubmit}
      onCancel={handleCancel}
      footer={
        <>
          <div key="1" className="project-modal-footer">
            <Button
              size="default"
              type="primary"
              key="submit"
              form="createPlaceForm"
              htmlType="submit"
            >
              {"Add"}
            </Button>
            <Button
              size="default"
              type="white"
              key="back"
              outlined
              onClick={handleCancel}
            >
              {t("common.cancelBtn")}
            </Button>
          </div>
        </>
      }
    >
      <div className="project-modal">
        <BasicFormWrapper>
          <Form
            form={form}
            id="createPlaceForm"
            name="createProject"
            onFinish={handleSubmit}
          >
            <TitleField />
            <DescriptionField />
            <EventTypeField />
            <ChannelLinkField required={true}/>
            <AddressField
              className="mt-20"
              setMarkerFromAddress={setMarkerFromAddress}
            />
            <MapField form={form} initValue={markerFromAddress} />
            <div className="add-photo-block">
              <Row gutter={15}>
                <Col xs={24}>
                  <div className="add-photo-content">
                    <Cards title={t("common.forms.images")}>
                      <ImagesField maxImages={10} form={form} />
                    </Cards>
                  </div>
                </Col>
              </Row>
            </div>
            <CategoriesField />
          </Form>
        </BasicFormWrapper>
      </div>
    </Modal>
  );
};
export default CreatePlaceModal;
