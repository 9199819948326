import React, { FC } from "react";
import { getUserId } from "@app/helpers/utils/user";
import {
  getPostFirstImage,
  getPostShortStartDate,
  getPostStartDate,
} from "@app/helpers/utils/posts";
import { Organaizer } from "@app/components/user/organaizer/organaizer";
import "./eventCard.scss";
import Meta from "@app/components/post/meta/meta";
import { getLocalUserId } from "@app/helpers/jwtHelper";
import { event } from "@app/store/mobx";
import { EventResponse as Event } from "@app/services/event/EventResponse";
import { EventType } from "@app/components/formsFields/eventTypeField";
import { Tag } from "antd";

interface Props {
  eventInfo: Event;
}

const EventCard: FC<Props> = ({ eventInfo }) => {
  const { description, title } = eventInfo;

  const userId = getUserId(eventInfo.postedBy);
  const postImg = getPostFirstImage(eventInfo);
  const postStartDate = eventInfo.channelLink
    ? getPostShortStartDate(eventInfo)
    : getPostStartDate(eventInfo);

  return (
    <div
      className="event-card"
      onMouseEnter={() => event.onHoverEvent(eventInfo._id)}
      onMouseLeave={() => event.onHoverEvent("")}
    >
      <div className="event-card__photo">
        <img className="event-card__photo-img" src={postImg} alt="event-img" />
      </div>
      <div className="event-card__content">
        <div className="event-card__content_description">
          { eventInfo?.eventType === EventType.PLACE 
            ? (<Tag color="purple">place</Tag>) 
            : (
            <div className="event-card__content_description-start-date">
              {postStartDate}
              <Tag color="gold">event</Tag>
            </div>)
          }
          <h2 className="event-card__content_description-title">{title}</h2>
          <div className="event-card__content_description-text">
            {description}
          </div>
        </div>
        <Organaizer size={"sm"} user={eventInfo.postedBy} />
        <Meta
          onView={event.onView}
          userId={getLocalUserId()}
          onLike={event.onLike}
          eventInfo={eventInfo}
        />
      </div>
    </div>
  );
};

export default EventCard;
