import { getPostLikesLength } from "@app/helpers/utils/posts";
import { UilHeart, UilHeartRate } from "@iconscout/react-unicons";
import { HeartOutlined, HeartFilled } from "@ant-design/icons";

import React from "react";
import "../meta.scss";
import { EventResponse as Event } from "@app/services/event/EventResponse";
import { socket } from "@app/helpers/http";

type LikesProps = {
  eventInfo: Event;
  onLike: (eventId: string) => void;
  userId: string;
};

const Likes: React.FC<LikesProps> = ({ eventInfo, onLike, userId }) => {
  const { likes, _id } = eventInfo;
  const filled = userId && likes.includes(userId);

  const handleLike = (e: React.MouseEvent<HTMLElement>) => {
    userId && e.preventDefault();
    userId && onLike(_id);
    userId && socket.emit("new notification to", eventInfo.postedBy._id);
  };

  return (
    <div className="meta likes" onClick={handleLike}>
      <span className="icon">
        {filled ? (
          <HeartFilled className="filled" size={18} />
        ) : (
          <HeartOutlined size={18} />
        )}
      </span>
      <span>{getPostLikesLength(eventInfo)} Likes</span>
    </div>
  );
};
export default Likes;
