import React, { FC, lazy } from "react";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { Badge, Empty } from "antd";
import { ChatContext } from "@app/store/provider";
import * as _ from "lodash";
import { textRefactor } from "@app/helpers/utils/common";
import { getUserFullName } from "@app/helpers/utils/user";
import { getDateShow, getTimeAgo } from "@app/helpers/utils/date";
import { getPostFirstImage, getPostTitle } from "@app/helpers/utils/posts";
import modals from "@app/store/mobx/modals";
import { useTranslation } from "react-i18next";

const GroupChat: FC = () => {
  const { t } = useTranslation();
  const { getGroupChats } = React.useContext(ChatContext);
  const groupChats = getGroupChats();
  const screenSize = document.documentElement.scrollWidth;

  const getLatestMessage = (latestMessage: any) =>
    latestMessage?.content ? latestMessage.content : t("chat.noMessages");
  const autorMessageName = (latestMessage: any) =>
    latestMessage?.sender ? getUserFullName(latestMessage.sender) + ": " : "";

  return (
    <ul>
      {!_.isEmpty(groupChats) ? (
        groupChats.map((chat: any, key: number) => {
          const { _id, post, latestMessage } = chat;
          const unread = chat?.unread?.count;
          const isToday = moment().isSame(chat.updatedAt, "day");

          const actionType =
            screenSize > 991 ? () => null : modals.onVisibleChatDrawer;

          return (
            <>
              <NavLink to={`${_id}`} onClick={actionType}>
                <li key={key} className="user-list-item">
                  <div className="user-list-item__wrapper">
                    <div className="avatar">
                      {post && post?.images && (
                        <img src={getPostFirstImage(post)} alt="image" />
                      )}
                    </div>
                    <div className="users-list-body">
                      <div className="users-list-body-title">
                        <h6>{(post && getPostTitle(post)) || "*Post name*"}</h6>
                        <div className="text-limit">
                          <p className="mb-0">
                            {autorMessageName(latestMessage)}
                            {textRefactor(getLatestMessage(latestMessage), 15)}
                          </p>
                        </div>
                      </div>
                      <div className="last-chat-time unread">
                        <small>
                          {isToday
                            ? getTimeAgo(chat.updatedAt)
                            : getDateShow(chat.updatedAt)}
                        </small>
                        {unread && (
                          <Badge
                            count={unread}
                            style={{ backgroundColor: "#52c41a" }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </li>
              </NavLink>
            </>
          );
        })
      ) : (
        <div className="empty-content">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      )}
    </ul>
  );
};

export default GroupChat;
