import $api, { API_URL } from "@app/helpers/http";
import axios, { AxiosResponse } from "axios";
import { RegistrationParams } from "@app/store/redux/authentication";
import {
  AuthRejection,
  AuthResponseExtend,
  ParamsVerificateEmail,
} from "./AuthResponse";
import { AuthResponse } from "./AuthResponse";

export default class AuthService {
  static async login(
    email: string,
    password: string
  ): Promise<AxiosResponse<AuthResponseExtend, AuthRejection>> {
    return $api.post("/auth/login", { email, password });
  }

  static async loginByGoogle(
    credential: string
  ): Promise<AxiosResponse<AuthResponseExtend, AuthRejection>> {
    return $api.post("/auth/loginByGoogle", { credential });
  }

  static async registration(
    params: RegistrationParams
  ): Promise<AxiosResponse<AuthResponseExtend, AuthRejection>> {
    return $api.post<AuthResponseExtend>("/auth/registration", { ...params });
  }

  static async activateEmail(email: string): Promise<AxiosResponse> {
    return $api.post<AuthResponse>("/auth/activateEmail", { email });
  }

  static async verificateEmail(
    params: ParamsVerificateEmail
  ): Promise<AxiosResponse> {
    return $api.post<AuthResponse>("/auth/verificateEmail", { ...params });
  }

  static async resetPassword({
    verificationCode,
    email,
  }: any): Promise<AxiosResponse> {
    return $api.post<AuthResponseExtend>("/auth/resetPassword", {
      verificationCode,
      email,
    });
  }

  static async newPassword({
    password,
    activatedLink,
  }: any): Promise<AxiosResponse> {
    return $api.post<AuthResponseExtend>("/auth/newPassword", {
      password,
      activatedLink,
    });
  }

  static async logout(): Promise<void> {
    return $api.post("/auth/logout");
  }

  static async checkAuth(): Promise<AxiosResponse> {
    return axios.get<AuthResponseExtend>(`${API_URL}/auth/refresh`, {
      withCredentials: true,
    });
  }

  static async changePassword(
    oldPassword: string,
    password: string
  ): Promise<AxiosResponse> {
    return $api.post<AuthResponse>("/auth/changePassword", {
      oldPassword,
      password,
    });
  }
}
