import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Profile from "./profile";
import Chat from "@app/pages/chat/ChatApp";
import { observer } from "mobx-react-lite";
import Layout from "@app/layout/layout";

const Admin = observer(() => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Layout>
      <Routes>
        <Route path="profile/*" element={<Profile />} />
        <Route path="profile/" element={<Navigate to="settings" replace />} />
        <Route path="chat/*" element={<Chat />} />
        <Route path="*" element={<Navigate to="profile/settings" replace />} />
      </Routes>
    </Layout>
  );
});

export default Admin;
