import axios from "axios";
import * as io from "socket.io-client";
import { getToken, setToken, setLocalUserId } from "../jwtHelper";
import { AuthResponseExtend } from "@app/services/auth/AuthResponse";

export const API_URL = process.env.API_URL;
export const BASE_API_URL = process.env.BASE_API_URL;

export const socket = io.connect(process.env.BASE_API_URL as string);

const $api = axios.create({
  withCredentials: true,
  baseURL: API_URL,
});

$api.interceptors.request.use((config: any) => {
  config.headers.Authorization = `Bearer ${getToken()}`;
  return config;
});

$api.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response?.status == 401 &&
      error.config &&
      !error.config._isRetry
    ) {
      originalRequest._isRetry = true;
      try {
        const response = await axios.get<AuthResponseExtend>(
          `${API_URL}/auth/refresh`,
          {
            withCredentials: true,
          }
        );
        setToken(response.data.accessToken);
        setLocalUserId(response.data.user.id);
        return $api.request(originalRequest);
      } catch (e) {
        console.log("НЕ АВТОРИЗОВАН");
      }
    }
    throw error;
  }
);

export default $api;
