import { DatePicker, Space, Form, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import moment, { Moment } from "moment";
import React from "react";
import { Select, Divider } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import qs from "qs";
import type { SelectProps } from "antd";

import "./headerDatePicker.scss";
import { disabledDate } from "@app/helpers/utils/date";
import LocationField from "@app/components/formsFields/locationField";

const { RangePicker } = DatePicker;
const { Option } = Select;

export class DatePickerConstructor {
  startDate;
  endDate;

  constructor(date: [string, string]) {
    this.startDate = moment(date[0]).startOf("day").toISOString();
    this.endDate = moment(date[1]).endOf("day").toISOString();
  }
}

export type FormValuesInit = {
  searchDate?: [string, string];
  location?: string;
};

export type FormValues = {
  location?: string;
  endDate: Date;
  startDate: Date;
};

type HeaderDatePickerProps = {
  onSubmit: any;
};

const HeaderDatePicker: React.FC<HeaderDatePickerProps> = ({ onSubmit }) => {
  const [form] = Form.useForm();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleSubmit = (value: FormValuesInit) => {
    const { searchDate, ...rest } = value;
    const searchDate$ = searchDate && new DatePickerConstructor(searchDate);
    onSubmit({ ...rest, ...searchDate$ });
    if (pathname !== "/") {
      navigate("/");
    }
  };

  return (
    <Form
      form={form}
      name="headerForm"
      onFinish={handleSubmit}
      className="header-date-picker"
    >
      <LocationField />
      <Divider type="vertical" />
      <Form.Item name="searchDate">
        <RangePicker format="DD-MM-YYYY" disabledDate={disabledDate} />
      </Form.Item>
      <div className="header-date-picker__button">
        <Button type="link" htmlType="submit">
          <SearchOutlined />
        </Button>
      </div>
    </Form>
  );
};

export default HeaderDatePicker;

let timeout: ReturnType<typeof setTimeout> | null;
let currentValue: string;

const fetch = (value: string, callback: Function) => {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  const fake = () => {
    const str = qs.stringify({
      code: "utf-8",
      q: value,
    });
    const result = [{ value: "sdfsdf", text: "wfwef" }];
    const data = result.map((item: any) => ({
      value: item[0],
      text: item[0],
    }));
    callback(data);
  };

  timeout = setTimeout(fake, 300);
};

export const SearchInput: React.FC<{
  placeholder: string;
  style?: React.CSSProperties;
}> = (props) => {
  const [data, setData] = React.useState<SelectProps["options"]>([]);
  const [value, setValue] = React.useState<string>();

  const handleSearch = (newValue: string) => {
    if (newValue) {
      fetch(newValue, setData);
    } else {
      setData([]);
    }
  };

  const handleChange = (newValue: string) => {
    setValue(newValue);
  };

  return (
    <Select
      showSearch
      value={value}
      placeholder={props.placeholder}
      style={props.style}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      notFoundContent={null}
      options={(data || []).map((d) => ({
        value: d.value,
        label: d.text,
      }))}
    />
  );
};
