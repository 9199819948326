import React, { useState } from "react";
import { Form, Col, Row, notification } from "antd";
import { Modal } from "../UIElements/modals/modals";
import { Button } from "../UIElements/buttons/buttons";
import { BasicFormWrapper } from "@app/pages/styled";
import { Cards } from "../cards/frame/cards-frame";
import _, { toNumber } from "lodash";
import { LatLngTuple } from "leaflet";
import { event, profile } from "@app/store/mobx";
import { toJS } from "mobx";
import { ICreateEditEvent } from "@app/models/Event";
import { PostDTO } from "@app/models/dtos/PostDto";
import { useTranslation } from "react-i18next";
import {
  DescriptionField,
  EndDateField,
  StartDateField,
  TitleField,
  AddressField,
  MapField,
  ImagesField,
  CategoriesField,
} from "../formsFields";
import AccessTypeField from "../formsFields/accessTypeField";
import ChannelLinkField from "../formsFields/channelLinkField";
import { isAdminUser } from "@app/helpers/admins";
import { observer } from "mobx-react-lite";
import { getLocalUserId } from "@app/helpers/jwtHelper";
import { EventType } from "../formsFields/eventTypeField";

type CreateEventModalProps = {
  visible: boolean;
  onCancel: () => void;
  onUploadFiles: any;
  onSubmit: (values: FormData) => Promise<PostDTO>;
};

const CreateEventModal: React.FC<CreateEventModalProps> = observer(({
  visible,
  onCancel,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const myProfile = toJS(profile.myProfile);
  const myId = getLocalUserId();
  const myCoordinates: LatLngTuple =
    myProfile && myProfile.city
      ? [toNumber(myProfile.city.latitude), toNumber(myProfile.city.longitude)]
      : [0, 0];
  const [markerFromAddress, setMarkerFromAddress] = useState<
    LatLngTuple | undefined
  >();

  const [form] = Form.useForm();

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  console.log('myProfile', myProfile)
  const handleSubmit = async (values: ICreateEditEvent) => {
    const formData = new FormData();

    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("accessType", values.accessType);
    //массив файлов
    values.imageUploades.forEach((file: any, i) => {
      formData.append(`files`, file.originFileObj);
    });
    if (values.startDate) {
      formData.append("startDate", values.startDate);
    }
    if (values.endDate) {
      formData.append("endDate", values.endDate);
    }
    formData.append("eventType", EventType.EVENT);
    formData.append("address", values.address);
    formData.append("coordinates", JSON.stringify(values.coordinates));
    if(!_.isEmpty(values.categories)){
      formData.append("categories", JSON.stringify(values.categories));
    }
    if(values.channelLink){
      formData.append("channelLink", values.channelLink);
    }

    await onSubmit(formData);
    onCancel();
    notification.success({ message: t("common.notification.eventCreated") });
  };

  return (
    <Modal
      type="primary"
      title={t("common.forms.createEvent")}
      visible={visible}
      onOk={onSubmit}
      onCancel={handleCancel}
      footer={
        <>
          <div key="1" className="project-modal-footer">
            <Button
              size="default"
              type="primary"
              key="submit"
              form="createEventForm"
              htmlType="submit"
            >
              {t("common.addNewEvent")}
            </Button>
            <Button
              size="default"
              type="white"
              key="back"
              outlined
              onClick={handleCancel}
            >
              {t("common.cancelBtn")}
            </Button>
          </div>
        </>
      }
    >
      <div className="project-modal">
        <BasicFormWrapper>
          <Form
            form={form}
            id="createEventForm"
            name="createProject"
            onFinish={handleSubmit}
          >
            <TitleField />
            <DescriptionField />
            <AccessTypeField form={form} />
            <Row gutter={15}>
              <Col md={12} xs={24}>
                <StartDateField required={!isAdminUser(myId)}/>
              </Col>
              <Col md={12} xs={24}>
                <EndDateField />
              </Col>
            </Row>
            {isAdminUser(myId) && (
              <div className="mt-20">
                <ChannelLinkField required={!isAdminUser(myId)} />
              </div>
            )}
            <AddressField
              className="mt-20"
              setMarkerFromAddress={setMarkerFromAddress}
            />
            <MapField form={form} initValue={markerFromAddress} />
            <div className="add-photo-block">
              <Row gutter={15}>
                <Col xs={24}>
                  <div className="add-photo-content">
                    <Cards title={t("common.forms.images")}>
                      <ImagesField form={form} />
                    </Cards>
                  </div>
                </Col>
              </Row>
            </div>
            <CategoriesField />
          </Form>
        </BasicFormWrapper>
      </div>
    </Modal>
  );
});
export default CreateEventModal;
