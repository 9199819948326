import { getUserFullName, getUserAvatar } from "@app/helpers/utils/user";
import { NavLink } from "react-router-dom";
import React from "react";
import "./organaizer.scss";
import { UserDTO } from "@app/models/dtos/UserDto";
import { useTranslation } from "react-i18next";
import { getLocalUserId } from "@app/helpers/jwtHelper";

type AutorProps = {
  user: UserDTO;
  size?: string;
};

export const Organaizer: React.FC<AutorProps> = ({ user, size }) => {
  const { t } = useTranslation();
  const avatar = getUserAvatar(user);
  const organizator = getUserFullName(user);

  const myId = getLocalUserId();

  return (
    <NavLink to={`/profile/${user._id}`}>
      <div className={`autor ${size}`}>
        <div className="autor__photo">
          <img className="autor__photo-img" src={avatar} alt="autor" />
        </div>
        <div className="autor__description">
          <div className="autor__description-title">
            {t("common.organizer")} <i>{user._id === myId ? "(you)" : ""}</i>
          </div>
          <div className="autor__description-name">{organizator}</div>
        </div>
      </div>
    </NavLink>
  );
};
