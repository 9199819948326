import { runInAction, makeAutoObservable, toJS } from "mobx";
import _ from "lodash";
import { UserId } from "@app/models/dtos/UserDto";

export type OnlineUsers = {
  userId: UserId;
  socketId: string;
};

class Modals {
  onlineUsers: OnlineUsers[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setOnlineUsers = (users: OnlineUsers[]) => {
    this.onlineUsers = users;
  };

  getOnlineUsers = () => {
    return this.onlineUsers;
  };
}

export default new Modals();
