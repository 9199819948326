import { useAppSelector } from "@app/store/redux/store";
import React, { lazy } from "react";

const NotFound = lazy(() => import("@app/pages/404/404"));

interface ProtectedProps {
  children: JSX.Element;
  isLoggedIn: boolean;
  isActivated: boolean;
}

const ProtectedAdminRoute = ({
  children,
  isLoggedIn,
  isActivated,
}: ProtectedProps) => {
  return isLoggedIn && isActivated ? children : <NotFound />;
};

const ProtectedAuthRoute = ({
  children,
  isLoggedIn,
  isActivated,
}: ProtectedProps) => {
  return isLoggedIn && isActivated ? <NotFound /> : children;
};

export { ProtectedAdminRoute, ProtectedAuthRoute };
