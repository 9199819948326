import React from "react";
import { useTranslation } from "react-i18next";
import { Form } from "antd";
import { disabledDate } from "@app/helpers/utils/date";
import { DatePicker } from "antd";
import moment from "moment";

type StartDateFieldProps = {
  initValue?: string;
  required: boolean;
};

const dateFormat = "YYYY-MM-DD HH:mm:ss";
const timeFormat = "HH:mm:ss";

const StartDateField: React.FC<StartDateFieldProps> = ({ initValue, required }) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      name="startDate"
      label={t("common.forms.startDate")}
      initialValue={initValue ? moment(initValue, dateFormat) : undefined}
      rules={[
        {
          required,
          message: t("common.validation.required") as string,
        },
      ]}
    >
      <DatePicker
        placeholder={t("common.forms.startDate") as string}
        format={dateFormat}
        disabledDate={disabledDate}
        showTime={
          initValue
            ? ({ defaultValue: moment(initValue, timeFormat) } as any)
            : true
        }
        className="datePicker"
      />
    </Form.Item>
  );
};
export default StartDateField;
