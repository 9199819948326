import React, { lazy, Suspense, FC } from "react";
import { Row, Col, Skeleton } from "antd";
import { Routes, Route, NavLink, Navigate } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import PrivetChat from "./overview/PrivetChat";
import GroupChat from "./overview/GroupChat";
import { Cards } from "@app/components/cards/frame/cards-frame";
import { ChatContext } from "@app/store/provider";
import { socket } from "@app/helpers/http";

import "./style.scss";
import { SingleChat$ } from "./overview/singleChat";
import { SingleGroupChat$ } from "./overview/SingleGroupChat";
import { useTranslation } from "react-i18next";

const SingleChat = lazy(() => import("./overview/singleChat"));
const SingleGroup = lazy(() => import("./overview/SingleGroupChat"));

interface ChatAppProps {}

const ChatApp: FC = () => {
  const { t } = useTranslation();
  const { fetchChats } = React.useContext(ChatContext);
  const { onlineUsers } = React.useContext(ChatContext);
  const screenSize = document.documentElement.scrollWidth;

  React.useEffect(() => {
    fetchChats();
  }, []);

  React.useEffect(() => {
    socket.on("message received", () => fetchChats());
  }, []);

  return (
    <div className="chat-page">
      <Row gutter={25}>
        <Col xxl={7} lg={10} xs={24}>
          <div className="chat-sidebar">
            <div className="search-tab">
              <nav>
                <ul>
                  <li>
                    <NavLink
                      className={({ isActive }) => (isActive ? "active" : "")}
                      to="./private"
                    >
                      {t("chat.privateChat")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={({ isActive }) => (isActive ? "active" : "")}
                      to="./group"
                    >
                      {t("chat.groupChat")}
                    </NavLink>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="search-body">
              <Scrollbars
                className="search-body-scrollbar"
                autoHide
                autoHideTimeout={500}
                autoHideDuration={200}
              >
                <Suspense
                  fallback={
                    <Cards headless>
                      <Skeleton avatar paragraph={{ rows: 10 }} active />
                    </Cards>
                  }
                >
                  <Routes>
                    <Route
                      path="private/*"
                      element={<PrivetChat onlineUsers={onlineUsers} />}
                    />
                    <Route path="group/*" element={<GroupChat />} />
                    <Route path="/*" element={<Navigate to={"private"} />} />
                  </Routes>
                </Suspense>
              </Scrollbars>
            </div>
          </div>
        </Col>
        <Col xxl={17} lg={14} xs={24}>
          <Suspense
            fallback={
              <Cards headless>
                <Skeleton avatar paragraph={{ rows: 10 }} active />
              </Cards>
            }
          >
            <Routes>
              <Route
                path="private/:chatId"
                element={
                  screenSize > 991 ? (
                    <SingleChat onlineUsers={onlineUsers} />
                  ) : (
                    <SingleChat$ onlineUsers={onlineUsers} />
                  )
                }
              />
              <Route
                path="group/:chatId"
                element={
                  screenSize > 991 ? <SingleGroup /> : <SingleGroupChat$ />
                }
              />
            </Routes>
          </Suspense>
        </Col>
      </Row>
    </div>
  );
};

export default ChatApp;
