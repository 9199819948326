import React from "react";
import { useTranslation } from "react-i18next";
import { Form } from "antd";
import { disabledDate } from "@app/helpers/utils/date";
import { DatePicker } from "antd";
import moment from "moment";

type StartDateFieldProps = {
  initValue?: string;
};

const dateFormat = "YYYY-MM-DD HH:mm:ss";
const timeFormat = "HH:mm:ss";

const EndDateField: React.FC<StartDateFieldProps> = ({ initValue }) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      name="endDate"
      label={t("common.forms.endDate")}
      initialValue={initValue ? moment(initValue, dateFormat) : undefined}
    >
      <DatePicker
        placeholder={t("common.forms.endDate") as string}
        format="YYYY-MM-DD HH:mm:ss"
        disabledDate={disabledDate}
        showTime={
          initValue ? { defaultValue: moment(initValue, timeFormat) } : true
        }
        className="datePicker"
      />
    </Form.Item>
  );
};
export default EndDateField;
