import React from "react";
import { useTranslation } from "react-i18next";
import { Form, Input } from "antd";

type TitleFieldProps = {
  initValue?: string;
};

const TitleField: React.FC<TitleFieldProps> = ({ initValue }) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      name="title"
      label={t("common.forms.title")}
      initialValue={initValue}
      rules={[
        {
          required: true,
          message: t("common.validation.required") as string,
        },
      ]}
    >
      <Input placeholder={t("common.forms.title") as string} />
    </Form.Item>
  );
};
export default TitleField;
