import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Settings from "./settings";
import { Ti18Languages } from "@app/helpers/i18n/config";
import LanguageSelector from "./languageSelector";
import AuthUser from "./authUser";
import { useAppSelector } from "@app/store/redux/store";
import NoAuthUser from "./noAuthUser";
import Message from "./Message";
import NotificationBox from "./Notification";
import notificationsData from "@app/store/mobx/notifications";
import "./auth.scss";
import { NotificationId } from "@app/models/dtos/NotificationDto";

const AuthInfo = React.memo(() => {
  const [state, setState] = useState<{ flag: Ti18Languages }>({
    flag: "en",
  });

  const { isLoggedIn, isActivated } = useAppSelector((state) => {
    return {
      isLoggedIn: state.auth.isAuth,
      isActivated: state.auth.user.isActivated,
    };
  });
  const { i18n } = useTranslation();
  const { flag } = state;

  const isActiveAndLoggedIn = isActivated && isLoggedIn;

  const onFlagChangeHandle = (
    value: Ti18Languages,
    e: React.MouseEvent<HTMLAnchorElement>
  ) => {
    e.preventDefault();
    setState({
      ...state,
      flag: value,
    });
    i18n.changeLanguage(value);
  };

  const onViewNotification = (id: NotificationId) => {
    notificationsData.openNotificationById(id);
  };

  useEffect(() => {
    if (isActiveAndLoggedIn) {
      notificationsData.fetchLastMessages();
      notificationsData.fetchLastNotifications();
    }
  }, [isActiveAndLoggedIn]);

  return (
    <>
      {isActiveAndLoggedIn && (
        <li className="nav-message">
          <Message />
        </li>
      )}
      {isActiveAndLoggedIn && (
        <li className="nav-notification">
          <NotificationBox onViewNotification={onViewNotification} />
        </li>
      )}
      {/* {isLoggedIn && <Settings />} */}
      <li className="nav-flag-select">
        <LanguageSelector flag={flag} onFlagChangeHandle={onFlagChangeHandle} />
      </li>
      <li className="nav-author">
        {isActiveAndLoggedIn ? <AuthUser /> : <NoAuthUser />}
      </li>
    </>
  );
});

export default AuthInfo;
