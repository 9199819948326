import React from "react";
import {
  MapContainer,
  TileLayer,
  useMapEvents,
  useMap,
  Marker,
  Popup,
} from "react-leaflet";
import L, { LatLngTuple } from "leaflet";
import { GmapWraper } from "./map-style";
import "leaflet/dist/leaflet.css";
import icon from "@assets/svg/map-icon.svg";
import _ from "lodash";
import { EventResponse } from "@app/services/event/EventResponse";
import { getPostTitle } from "@app/helpers/utils/posts";
import { Link } from "react-router-dom";
import { PostId } from "@app/models/dtos/PostDto";
import { observer } from "mobx-react-lite";
import { event } from "@app/store/mobx";
import modals from "@app/store/mobx/modals";

const DefaultIcon = (focused: boolean) =>
  L.icon({
    iconUrl: icon,
    iconSize: focused ? [45, 65] : [35, 55],
  });

export type Marker = {
  coordinates: LatLngTuple;
};

type MapProps = {
  width: string;
  height: string;
  mapPosition: LatLngTuple;
  zoom: number;
  setMarker?: React.Dispatch<React.SetStateAction<LatLngTuple>>;
  marker?: LatLngTuple;
  events?: EventResponse[];
  isFocused?: (id: PostId) => boolean;
};

type MarkerComponentProps = {
  saveMarker: (value: React.SetStateAction<L.LatLngTuple>) => void;
};

function MarkerComponent({ saveMarker }: MarkerComponentProps) {
  const map = useMapEvents({
    click: (e) => {
      const { lat, lng } = e.latlng;
      saveMarker([lat, lng]);
    },
  });
  return null;
}

const MapLeaflet: React.FC<MapProps> = observer(
  ({
    width,
    height,
    mapPosition,
    zoom,
    marker,
    setMarker,
    events,
    isFocused,
  }) => {
    const saveMarker = (markerCoord: LatLngTuple) => {
      if (setMarker) setMarker(markerCoord);
    };

    const handleMarkerClick = async (id: PostId) => {
      event.onHoverEvent(id);
      await event.fetchEventById(id);
      modals.onShowMapEvent();
    };

    return (
      <GmapWraper width={width} height={height}>
        <MapContainer center={mapPosition} zoom={zoom}>
          <MarkerComponent saveMarker={saveMarker} />
          <ChangeView center={mapPosition} zoom={zoom} />
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          {marker && <Marker icon={DefaultIcon(false)} position={marker} />}
          {events &&
            !_.isEmpty(events) &&
            events.map((event, index) => {
              const isShowBig = (isFocused && isFocused(event._id)) || false;
              return (
                <>
                  <Marker
                    icon={DefaultIcon(isShowBig)}
                    key={index}
                    position={event.location.coordinates}
                    eventHandlers={{
                      click: () => handleMarkerClick(event._id),
                    }}
                  ></Marker>
                </>
              );
            })}
        </MapContainer>
      </GmapWraper>
    );
  }
);
export default MapLeaflet;

type ChangeViewProps = {
  center: LatLngTuple;
  zoom: number;
};

const ChangeView = ({ center, zoom }: ChangeViewProps) => {
  const map = useMap();
  map.setView(center, zoom);
  return null;
};
