import React, { useState, Fragment, FC, useEffect } from "react";
import { Drawer } from "antd";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Scrollbars } from "react-custom-scrollbars";
import { SingleChatWrapper, BackShadowEmoji } from "../style";
import Heading from "@app/components/heading/heading";
import { Cards } from "@app/components/cards/frame/cards-frame";
import { ChatContext } from "@app/store/provider";
import { Empty } from "antd";
import * as _ from "lodash";
import { socket } from "@app/helpers/http";
import dotetGif from "@assets/dots.gif";

import { getUserFullName } from "@app/helpers/utils/user";
import { OnlineUsers } from "@app/store/mobx/socket";
import notifications from "@app/store/mobx/notifications";
import modals from "@app/store/mobx/modals";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import Send from "../components/send";
import ChatMessage from "../components/chatMessage";
import { getLocalUserId } from "@app/helpers/jwtHelper";

interface Props {
  chatIdProps?: string;
  onlineUsers: OnlineUsers[];
}

const SingleChat: FC<Props> = React.memo(({ chatIdProps, onlineUsers }) => {
  const bottomRef = React.useRef<any>(null);
  const params = useParams();
  const { chatId } = params;
  const chatId$ = chatIdProps || chatId;

  if (!chatId$) {
    return null;
  }

  const {
    fetchChat,
    fetchChatMessages,
    getMessages,
    markAllMessagesAsRead,
    getPenPalInfo,
    cleanMessages,
    getMyFullName,
  } = React.useContext(ChatContext);

  const messages = getMessages();
  const [pickerShow, setPickerShow] = useState(false);
  const [lastMessageWasReaded, setLastMessageWasReaded] =
    useState<boolean>(false);
  const penPalInfo = getPenPalInfo();
  const myId = getLocalUserId();

  const isSenderOnline = onlineUsers
    .map((user) => user.userId)
    .includes(penPalInfo?._id);

  const scrollToBottom = () => {
    bottomRef.current.scrollToBottom();
  };

  const [typing, setTyping] = React.useState<boolean>(false);

  React.useLayoutEffect(() => {
    scrollToBottom();
  });

  React.useEffect(() => {
    setLastMessageWasReaded(false);
    markAllMessagesAsRead(chatId$).then(() => {
      socket.emit("messages was readed", {
        room: chatId$,
        userTo: penPalInfo?._id,
      });
    });
    socket.on("readed message", (userTo) => {
      if (!_.isEmpty(messages) && userTo === myId) {
        setLastMessageWasReaded(true);
        notifications.fetchLastMessages();
      }
    });
  }, [messages]);

  React.useEffect(() => {
    fetchChat(chatId$);
    fetchChatMessages(chatId$);
    markAllMessagesAsRead(chatId$).then(async () => {
      await notifications.fetchLastMessages();
    });
  }, [chatId$]);

  React.useEffect(() => {
    socket.emit("join room", chatId$);
    socket.on("typing", () => setTyping(true));
    socket.on("stop typing", () => setTyping(false));
    socket.on("new message", () => fetchChatMessages(chatId$));

    return () => {
      socket.emit("leave room", chatId$);
      cleanMessages();
    };
  }, [chatId$]);

  let showToday = 0;

  return (
    <SingleChatWrapper>
      <Cards
        title={
          <>
            <Heading as="h5">{getUserFullName(penPalInfo)}</Heading>
            <p>
              {isSenderOnline ? "Online" : "Offline"}{" "}
              <span className={isSenderOnline ? "online" : "offline"}></span>
            </p>
          </>
        }
      >
        <ul className="atbd-chatbox">
          <Scrollbars
            className="custom-scrollbar"
            autoHide
            ref={bottomRef}
            autoHideTimeout={500}
            autoHideDuration={200}
          >
            {!_.isEmpty(messages) ? (
              messages.map((mes: any, index: number) => {
                const isToday = moment().isSame(mes.createdAt, "day");
                isToday ? showToday++ : null;

                return (
                  <>
                    {isToday && showToday == 1 && (
                      <p className="time-connector text-center text-capitalize">
                        <span>today</span>
                      </p>
                    )}
                    <Fragment key={index}>
                      <ChatMessage
                        isToday={isToday}
                        message={mes}
                        sender={mes.sender}
                        lastMessageWasReaded={lastMessageWasReaded}
                        penPalId={penPalInfo?._id}
                      />
                    </Fragment>
                  </>
                );
              })
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
            {typing && (
              <div className="typingDots">
                <img src={dotetGif} alt="Typing dots" />
              </div>
            )}
          </Scrollbars>
        </ul>
        <Send
          myFullName={getMyFullName()}
          scrollToBottom={scrollToBottom}
          chatId={chatId$}
          penPalInfoId={penPalInfo?._id}
        />
      </Cards>
    </SingleChatWrapper>
  );
});

export default SingleChat;

export const SingleChat$: FC<Props> = observer((props) => {
  const { t } = useTranslation();
  const screenSize = document.documentElement.scrollWidth;
  const drawerSize = screenSize > 575 ? "500px" : "100%";

  const { chatId } = useParams();

  useEffect(() => {
    if (chatId) {
      modals.onVisibleChatDrawer();
    }
  }, [chatId]);

  return (
    <Drawer
      className="drawer-chat-wrapper single"
      title={t("chat.chat")}
      width={drawerSize}
      placement="right"
      onClose={modals.onCloseChatDrawer}
      open={modals.chatDrawer}
      style={{ zIndex: 999 }}
    >
      <div className="drawer-chat">
        <SingleChat {...props} />
      </div>
    </Drawer>
  );
});
