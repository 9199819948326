import React, { FC } from "react";
import { ModalStyled } from "./styled";
import { Button } from "../buttons/buttons";

type ModalProps = {
  onCancel?: () => void;
  // onOk: () => void;
  onOk?: any;
  visible: boolean;
  title: string;
  className?: string;
  type:
    | "primary"
    | "secondary"
    | "success"
    | "info"
    | "warning"
    | "danger"
    | "link"
    | "dark"
    | "light"
    | "gray"
    | "white"
    | "dashed"
    | "error"
    | "extra-light"
    | "default";
  footer?: React.ReactNode;
  width?: number;
  color?: boolean | string;
  children: React.ReactNode;
};

const Modal: FC<ModalProps> = (props) => {
  const {
    onCancel,
    className = "atbd-modal",
    onOk,
    visible,
    title,
    type,
    color,
    footer,
    width = 620,
    children,
  } = props;

  return (
    <ModalStyled
      title={title}
      open={visible}
      onOk={onOk}
      onCancel={onCancel}
      type={color ? type : false}
      width={width}
      className={className}
      footer={
        footer || footer === null
          ? footer
          : [
              <Button type="secondary" key="back" onClick={onCancel}>
                Cancel
              </Button>,
              <Button type={type} key="submit" onClick={onOk}>
                Save Change
              </Button>,
            ]
      }
    >
      {children}
    </ModalStyled>
  );
};

const alertModal = ModalStyled;
export { Modal, alertModal };
