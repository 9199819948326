import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Input, Select, Alert, FormInstance } from "antd";

type AccessTypeFieldProps = {
  initValue?: string;
  disabled?: boolean;
  form?: FormInstance;
};

export enum AccessType {
  COMMON = "COMMON",
  SUBSCRIBERS = "SUBSCRIBERS",
  PRIVATE = "PRIVATE",
}

const AccessTypeField: React.FC<AccessTypeFieldProps> = ({
  initValue,
  disabled,
  form,
}) => {
  const { t } = useTranslation();
  const [fieldValue, setFieldValue] = useState<AccessType | undefined>(
    initValue as AccessType
  );

  const handleChange = (value: AccessType) => {
    setFieldValue(value);
    form?.setFieldValue("accessType", value);
  };

  const getAlertText = () => {
    switch (fieldValue) {
      case "COMMON":
        return "The event is available to all users";
        break;
      case "SUBSCRIBERS":
        return "The event is only available to your subscribers";
        break;
      case "PRIVATE":
        return "The event will be available by invitation only";
        break;
      default:
        return undefined;
    }
  };

  return (
    <Form.Item
      name="accessType"
      label={"Access Type"}
      initialValue={initValue}
      rules={[
        {
          required: true,
          message: t("common.validation.required") as string,
        },
      ]}
    >
      <Select
        showSearch
        placeholder="Select access type"
        optionFilterProp="children"
        onChange={handleChange}
        value={fieldValue}
        disabled={disabled}
        options={[
          {
            value: AccessType.COMMON,
            label: "Common",
          },
          {
            value: AccessType.SUBSCRIBERS,
            label: "For subscribers",
          },
          {
            value: AccessType.PRIVATE,
            label: "Private",
          },
        ]}
      />
      {fieldValue && (
        <Alert
          message={getAlertText()}
          type="info"
          showIcon
          // closable
        />
      )}
    </Form.Item>
  );
};
export default AccessTypeField;
