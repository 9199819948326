import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";

const NotFound = lazy(() => import("@app/pages/404/404"));
const Settings = lazy(() => import("@app/pages/profile/settings/Settings"));
const MyProfile = lazy(() => import("@app/pages/profile/profile/myProfile"));

const ProfileRoute = () => {
  return (
    <Routes>
      <Route path="settings/*" element={<Settings />} />
      <Route path="myProfile/*" element={<MyProfile />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default ProfileRoute;
