import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import themeLayoutReducer from "./themeLayout";
import themeUserReducer from "./themeUsers";
import authReducer from "./authentication";
import profileReducer from "./profile";
import formValidationReducer from "./formValidator";

export const store = configureStore({
  reducer: {
    themeLayout: themeLayoutReducer,
    themeUser: themeUserReducer,
    auth: authReducer,
    profile: profileReducer,
    formValidation: formValidationReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
