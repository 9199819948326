import React, { SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Input, Select } from "antd";
import axios from "axios";
import { debounce } from "lodash";
import { LatLngTuple } from "leaflet";

const { Option } = Select;

type AddressFieldProps = {
  initValue?: string;
  className?: string;
  setMarkerFromAddress?: React.Dispatch<SetStateAction<LatLngTuple>>;
};

interface AddressResult {
  display_name: string;
  lat?: string;
  lon?: string;
}

const AddressField: React.FC<AddressFieldProps> = ({
  className,
  initValue,
  setMarkerFromAddress,
}) => {
  const { t } = useTranslation();
  const [addresses, setAddresses] = useState<AddressResult[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");

  const searchAddresses = React.useRef(
    debounce(async (value: string) => {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/search?format=json&q=${value}`
      );
      if (response.data.length > 0) {
        setAddresses(response.data);
      } else {
        setAddresses([{ display_name: value }]); // Если адрес не найден, сохраняем введенное значение
      }
    }, 2000)
  ).current;

  const onSearch = (value: string) => {
    setSearchValue(value);
    if (value.trim() === "") return;
    searchAddresses(value);
  };

  const onChange = (value: string) => {
    setSearchValue(value);
    const selectedAddress = addresses.find(
      (addr) => addr.display_name === value
    );
    if (
      selectedAddress &&
      selectedAddress.lat &&
      selectedAddress.lon &&
      setMarkerFromAddress
    ) {
      setMarkerFromAddress([
        Number(selectedAddress.lat),
        Number(selectedAddress.lon),
      ]);
    }
  };

  return (
    <Form.Item
      className={className}
      name="address"
      label={t("common.forms.adress")}
      initialValue={initValue}
      rules={[
        {
          required: true,
          message: t("common.validation.required") as string,
        },
      ]}
    >
      {/* <Input /> */}
      <Select
        showSearch
        value={searchValue}
        placeholder="Search for an address"
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        onSearch={onSearch}
        onChange={onChange}
        notFoundContent={null}
        style={{ width: "100%" }}
      >
        {addresses.map((address) => (
          <Option key={address.display_name} value={address.display_name}>
            {address.display_name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};
export default AddressField;
