import React from "react";
import { Link } from "react-router-dom";
import { Button, Drawer } from "antd";
import "./header.scss";
import HeaderDatePicker, {
  FormValues,
} from "@app/components/header/headerDatePicker/headerDatePicker";
import { event } from "@app/store/mobx";
import AuthInfo from "@app/components/header/auth-info/authInfo";
import modals from "@app/store/mobx/modals";
import { observer } from "mobx-react-lite";
import { UilMultiply } from "@iconscout/react-unicons";

type HeaderProps = {};

const Header: React.FC<HeaderProps> = observer(() => {
  const onSubmitDateSearch = (values: FormValues) => {
    event.fetchAllEvents(values);
  };

  return (
    <>
      <Drawer
        className="mobile-author-drawer"
        title={false}
        closable={true}
        onClose={modals.onCloseMobileActions}
        open={modals.showMobileActions}
        placement="top"
        height={70}
      >
        <div className="navbar">
          <div className="navbar-right">
            <ul className="navbar-right__menu">
              <AuthInfo />
            </ul>
          </div>
        </div>
      </Drawer>
      <header className="header-top">
        <nav className="navbar">
          {/* <!-- navbar-left --> */}
          <div className="navbar-left">
            <div className="logo-area">
              <Link
                className="navbar-brand"
                to="/"
                onClick={modals.onScrollTop}
              >
                <img src={require(`@assets/svg/logo.svg`)} alt="logo-pllace" />
              </Link>
              <Button
                onClick={modals.toggleSidebar}
                className="sidebar-toggle"
                type="link"
              >
                <div style={{ position: "relative", display: "inline-block" }}>
                  <UilMultiply
                    className={`svg animated-icon ${
                      modals.sideBarDrawer ? "show-icon" : "hide-icon"
                    }`}
                    size={22}
                  />
                  <img
                    className={`svg animated-icon ${
                      !modals.sideBarDrawer ? "show-icon" : "hide-icon"
                    }`}
                    src={require(`@assets/svg/left-bar.svg`)}
                    alt="img"
                  />
                </div>
              </Button>
            </div>
          </div>
          {/* <!-- ends: navbar-left --> */}

          <div className="navbar-middle">
            <HeaderDatePicker onSubmit={onSubmitDateSearch} />
          </div>

          {/* <!-- navbar-right --> */}
          <div className="navbar-right">
            <ul className="navbar-right__menu">
              <AuthInfo />
            </ul>
            <div className="navbar-right__mobileAction">
              <Button
                type="link"
                onClick={modals.toggleMobileActions}
                className="btn-author-action"
              >
                <UilMultiply
                  className={`svg animated-icon ${
                    modals.showMobileActions ? "show-icon" : "hide-icon"
                  }`}
                  size={22}
                />
                <img
                  className={`svg animated-icon ${
                    !modals.showMobileActions ? "show-icon" : "hide-icon"
                  }`}
                  src={require(`@assets//svg/more-vertical.svg`)}
                  alt="more-vertical"
                />
              </Button>
            </div>
          </div>
          {/* <!-- ends: .navbar-right --> */}
        </nav>
      </header>
    </>
  );
});
export default Header;
