import { getPostViewLength } from "@app/helpers/utils/posts";
import { UilEye } from "@iconscout/react-unicons";
import React, { useEffect, useRef } from "react";
import "../meta.scss";
import { EventResponse as Event } from "@app/services/event/EventResponse";
import { useOnScreen } from "@app/helpers/hooks/useOnScreen";
import { PostId } from "@app/models/dtos/PostDto";

type ViewsProps = {
  eventInfo: Event;
  onView: (postId: PostId) => void;
};

const Views: React.FC<ViewsProps> = ({ eventInfo, onView }) => {
  const refBlog = useRef(null);
  const refViewed = useRef(false);
  const isViewed = useOnScreen(refBlog);

  useEffect(() => {
    if (isViewed && !refViewed.current) {
      refViewed.current = true;
      onView(eventInfo._id);
    }
  }, [isViewed]);

  return (
    <div className="meta views" ref={refBlog}>
      <span className="icon">
        <UilEye size={18} />
      </span>
      <span>{getPostViewLength(eventInfo) || 0} Views</span>
    </div>
  );
};
export default Views;
