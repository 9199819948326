import React, { FC } from "react";
import { DatePicker, Form } from "antd";
import { Button } from "@app/components/UIElements/buttons/buttons";
import { disabledDate } from "@app/helpers/utils/date";
import {
  DatePickerConstructor,
  FormValuesInit,
  SearchInput,
} from "../headerDatePicker/headerDatePicker";
import { EventParams } from "@app/store/mobx/event";
import LocationField from "@app/components/formsFields/locationField";

const { RangePicker } = DatePicker;

interface MobileDatePickerProps {
  onSubmit: (values: EventParams) => void;
}

const MobileDatePicker: FC<MobileDatePickerProps> = ({ onSubmit }) => {
  const [form] = Form.useForm();

  const handleSubmit = (value: FormValuesInit) => {
    const { searchDate, ...rest } = value;
    const searchDate$ = searchDate && new DatePickerConstructor(searchDate);
    onSubmit({ ...rest, ...searchDate$ } as EventParams);
  };

  return (
    <Form form={form} name="headerFormMobile" onFinish={handleSubmit}>
      <LocationField className={"mobile-location"} />
      <Form.Item name="searchDate">
        <RangePicker
          popupClassName="mobile-date-picker-component"
          format="DD-MM-YYYY"
          disabledDate={disabledDate}
        />
      </Form.Item>
      <Button width="full" size="default" htmlType="submit" type="primary">
        Search Event
      </Button>
    </Form>

    // <div style={{ padding: "10px" }}>
    //     {/* <div className="mt-10">
    //       <SearchInput placeholder="Location:" />
    //     </div> */}
    //     <div className="mt-10">
    //       <RangePicker
    //         // popupClassName={'header__dropdown-custom-auth'}
    //         popupClassName="mobile-date-picker-component"
    //         style={{ width: "min-content" }}
    //         disabledDate={disabledDate}
    //       />
    //     </div>
    //     <div className="mt-10">
    //       <Button width="full" size="default" type="primary">
    //         Search Event
    //       </Button>
    //     </div>
    // </div>
  );
};

export default MobileDatePicker;
