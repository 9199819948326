import React, { SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Input, Select } from "antd";
import axios from "axios";
import { debounce } from "lodash";
import { LatLngTuple } from "leaflet";
import { event } from "@app/store/mobx";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";

const { Option } = Select;

type LocationFieldProps = {
  initValue?: LocationResult;
  className?: string;
  setMarkerFromAddress?: React.Dispatch<SetStateAction<LatLngTuple>>;
};

export interface LocationResult {
  city: string;
  city_ascii: string;
  lat: number;
  lng: number;
  country: string;
  iso2: string;
  iso3: string;
  admin_name: string;
  capital: string;
  population: number;
  id: number;
}

const LocationField: React.FC<LocationFieldProps> = observer(
  ({ className, initValue, setMarkerFromAddress }) => {
    const { t } = useTranslation();
    const cityStore = toJS(event.selectedCity);
    const [cities, setCities] = useState<LocationResult[]>([]);

    const searchCities = React.useRef(
      debounce(async (value: string) => {
        const response: any = await axios.post(`${process.env.API_URL}/city/`, {
          city: value,
        });
        setCities(response.data);
      }, 1000)
    ).current;

    const onSearch = (value: string) => {
      if (value.trim() === "") return;
      searchCities(value);
    };

    const onChange = (value: number) => {
      const selectedCity = cities.find((city) => city.id == value);
      if (selectedCity) {
        event.setSelectedCity(selectedCity);
      }
    };

    return (
      <Form.Item
        className={className}
        name="location"
        initialValue={
          cityStore ? `${cityStore.city}, ${cityStore.country}` : undefined
        }
      >
        <Select
          showSearch
          placeholder="City:"
          value={cityStore ? cityStore.id : undefined}
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onSearch={onSearch}
          onChange={onChange}
          notFoundContent={null}
          style={{ width: "100%" }}
        >
          {cities.map((city) => (
            <Option key={city.id}>
              {city.city}, {city.country}
            </Option>
          ))}
        </Select>
      </Form.Item>
    );
  }
);
export default LocationField;
