import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LatLngTuple } from "leaflet";
import { Form, FormInstance } from "antd";
import MapLeaflet from "../post/map/Map";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import { event } from "@app/store/mobx";

type MapFieldProps = {
  form: FormInstance;
  initValue?: LatLngTuple;
};

const MapField: React.FC<MapFieldProps> = observer(({ form, initValue }) => {
  const { t } = useTranslation();
  const geoCoordinates = toJS(event.geoCoordinates);
  const [marker, setMarker] = useState<LatLngTuple | undefined>(initValue);

  const markerRule = {
    validator(_: any, value: any) {
      if (marker) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(t("common.validation.map") as string));
    },
  };

  useEffect(() => {
    setMarker(initValue);
  }, [initValue]);

  useEffect(() => {
    form.setFieldsValue({ coordinates: marker });
  }, [marker, form]);

  return (
    <Form.Item name="coordinates" rules={[markerRule]} initialValue={initValue}>
      <MapLeaflet
        mapPosition={initValue ? initValue : geoCoordinates}
        width="100%"
        height="300px"
        zoom={12}
        marker={marker}
        setMarker={setMarker}
      />
    </Form.Item>
  );
});
export default MapField;
