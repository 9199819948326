import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Empty, Button } from "antd";
import { Scrollbars } from "react-custom-scrollbars";
import EventCard from "@app/components/post/cards/EventCard";
import { event } from "@app/store/mobx";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import MapLeaflet from "@app/components/post/map/Map";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import _ from "lodash";
import { EventResponse as Event } from "@app/services/event/EventResponse";
import "./pages.scss";
import { PostId } from "@app/models/dtos/PostDto";
import { UilListUl, UilMap } from "@iconscout/react-unicons";
import { debounce } from "lodash";
import modals from "@app/store/mobx/modals";
import MapEvent from "@app/components/mapEvent/mapEvent";
import { LatLngTuple } from "leaflet";
import axios from "axios";
import { SelectedCity } from "@app/store/mobx/event";
import UiCarousel from "@app/components/UIElements/carousel/carousel";
import { Category, ECategories } from "@app/helpers/categories";

const MainPage = observer(() => {
  const [searchParams, setSearchParams] = useSearchParams();

  const scrollTopRef = useRef<Scrollbars>(null);
  const events: Event[] = toJS(event.eventsState);
  const geoCoordinates = toJS(event.geoCoordinates);
  const [isMapHidden, setMapHidden] = useState(true);
  const [screenSize, setScreenSize] = useState(
    document.documentElement.scrollWidth
  );

  const cityParam = searchParams.get("city");
  const categoryParam = searchParams.get("category") as ECategories;

  const isMobile = screenSize < 575;
  const showEventBlock = isMobile ? isMapHidden : true;
  const showMapBlock = isMobile ? !isMapHidden : true;

  useEffect(() => {
    const handleResize = debounce(() => {
      setScreenSize(document.documentElement.scrollWidth);
    }, 1000);

    window.addEventListener("resize", handleResize);

    return () => {
      event.clearEventsState();
      window.removeEventListener("resize", handleResize);
      handleResize.cancel();
    };
  }, []);

  const toggleMap = () => {
    setMapHidden((prev) => !prev);
  };

  const setCity = async (city: string) => {
    const response = await axios.post(`${process.env.API_URL}/city/`, {
      city
    });
    const citySearch = _.head(response.data)
    if (!_.isEmpty(citySearch)) {
      event.setSelectedCity(citySearch as SelectedCity)
      event.fetchAllEvents()
    }
  }

  React.useEffect(() => {
    if (cityParam) {
      setCity(cityParam)
    }
  }, [cityParam]);

  useEffect(() => {
    if (categoryParam) {
      event.fetchAllEvents({ categories: categoryParam });
    }
    else {
      event.fetchAllEvents();
    }
  }, [categoryParam]);

  useEffect(() => {
    if (scrollTopRef.current) {
      scrollTopRef.current.scrollTop(0);
    }
  }, [modals.scrollTop]);

  const onScrollBodyTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const isFocused = (id: PostId) => {
    return id === event.getFocucedEvent();
  };

  const handleCategory = (category: Category) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.set('category', category.value);

    if (category.value === ECategories.RESET) {
      searchParams.delete('category')
      setSearchParams(searchParams)
      return
    }

    setSearchParams(newSearchParams);
  }

  return (
    <div>
      <div>
        <Row gutter={20} className="main-page">
          {showEventBlock && (
            <Col md={15} xs={24} className={`events-content`}>
              <Scrollbars
                ref={scrollTopRef}
                className="custom-scrollbar"
                autoHide
                autoHideTimeout={500}
                autoHideDuration={200}
                style={{ height: "calc(100vh - 70px)" }}
                onScrollStart={onScrollBodyTop}
                onScrollStop={onScrollBodyTop}
              >
                <div className="filter desktop">
                  <UiCarousel activeCategory={categoryParam} onSetCategory={handleCategory} />
                </div>
                <Row
                  gutter={12}
                  className={`mt-sm-10 mt-10 main-page__content`}
                >
                  {!_.isEmpty(events) ? (
                    events.map((event: Event) => {
                      return (
                        <Col
                          key={event._id}
                          xxl={6}
                          xl={8}
                          sm={12}
                          xs={24}
                          style={{ marginBottom: "24px" }}
                        >
                          <Link to={`/event/${event._id}`}>
                            <EventCard eventInfo={event} />
                          </Link>
                        </Col>
                      );
                    })
                  ) : (
                    <div className="empty-content">
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                  )}
                </Row>
              </Scrollbars>
            </Col>
          )}
          {showMapBlock && (
            <Col md={9} xs={24} className={`main-page__map`}>
              <div style={{ height: "calc(100vh - 70px)" }}>
                <MapLeaflet
                  mapPosition={geoCoordinates}
                  width="100%"
                  height="100%"
                  zoom={12}
                  events={events}
                  isFocused={isFocused}
                />
              </div>
              {modals.mapEvent && <MapEvent isMobile={isMobile} />}
            </Col>
          )}
        </Row>
        <div className="map-icon">
          <Button
            onClick={toggleMap}
            shape="round"
            icon={isMapHidden ? <UilMap size={16} /> : <UilListUl size={16} />}
            size={"middle"}
          >
            {isMapHidden ? "Map" : "List"}
          </Button>
        </div>
      </div>
    </div>
  );
});

export default MainPage;
