import React, { FC, ReactNode } from "react";
import * as headings from "./style";

interface HeadingProps {
  as: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "h7";
  children: ReactNode;
  className?: string;
  id?: string;
}

const Heading: FC<HeadingProps> = ({ as = "h1", ...props }) => {
  const { children, className, id } = props;
  const StyledHeading = as ? (headings as any)[as.toUpperCase()] : headings.H1;

  return (
    <StyledHeading className={className} id={id}>
      {children}
    </StyledHeading>
  );
};

export default Heading;
