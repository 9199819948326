import { Badge, Popover, Empty } from "antd";
import React, { FC, RefObject, useEffect, useRef } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { Link } from "react-router-dom";
import Heading from "@app/components/heading/heading";
import notificationsData from "@app/store/mobx/notifications";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import {
  UilAt,
  UilCommentAlt,
  UilHeart,
  UilInvoice,
  UilUpload,
  UilUserPlus,
} from "@iconscout/react-unicons";
import { getTimeAgo } from "@app/helpers/utils/date";
import { getUserFullName } from "@app/helpers/utils/user";
import {
  NotificationDTOExtend,
  NotificationId,
} from "@app/models/dtos/NotificationDto";
import _ from "lodash";
import useVisibility from "@app/helpers/hooks/useVisibility";
import { useTranslation } from "react-i18next";

type Props = {
  onViewNotification: (id: NotificationId) => void;
};

const NotificationBox: FC<Props> = observer(({ onViewNotification }) => {
  const { t } = useTranslation();
  const lastNotifications = toJS(notificationsData.lastNotificationsState);
  const countNotifications = toJS(notificationsData.countNotificationsState);
  const containerRef = useRef<HTMLDivElement>(null);

  const content = (
    <div className="dropdown-wrapper" ref={containerRef}>
      <Heading as="h5" className="dropdown-wrapper__title">
        <span className="title-text">{t("common.header.notifications")}</span>
        {!_.isEmpty(lastNotifications) && (
          <Badge color="green" count={countNotifications} />
        )}
      </Heading>
      <Scrollbars autoHeight autoHide>
        <ul>
          {!_.isEmpty(lastNotifications) ? (
            lastNotifications.map((notification) => (
              <Notification
                containerRef={containerRef}
                onViewNotification={onViewNotification}
                notification={notification}
              />
            ))
          ) : (
            <div className="empty-content">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          )}
        </ul>
      </Scrollbars>
      <Link
        className="dropdown-wrapper__more"
        to="/admin/profile/myProfile/activity"
      >
        {t("common.header.allNotifications")}
      </Link>
    </div>
  );

  return (
    <Popover
      showArrow={false}
      zIndex={989}
      overlayClassName={"header__dropdown-custom-notification"}
      placement="bottomLeft"
      content={content}
    >
      <Badge
        dot={countNotifications ? true : false}
        className={countNotifications ? "bell" : ""}
        offset={[-2, 0]}
      >
        <Link to="#">
          <img src={require("@assets/svg/bell.svg")} />
        </Link>
      </Badge>
    </Popover>
  );
});

export default NotificationBox;

interface NotificationProps {
  notification: NotificationDTOExtend;
  onViewNotification: (id: NotificationId) => void;
  containerRef: RefObject<HTMLDivElement>;
}

type NotificationInnerProps = Omit<
  NotificationProps,
  "onViewNotification" | "containerRef"
>;

export const Notification: FC<NotificationProps> = ({
  notification,
  onViewNotification,
  containerRef,
}) => {
  const defineIconType = (messageType: string) => {
    switch (messageType) {
      case "follow":
        return <UilAt size={14} />;
        break;
      case "newPost":
        return <UilUpload size={14} />;
        break;
      case "invite":
        return <UilInvoice size={14} />;
        break;
      case "newParticipate":
        return <UilUserPlus size={14} />;
        break;
      case "postLike":
        return <UilHeart size={14} />;
        break;
      case "newMessage":
        return <UilCommentAlt size={14} />;
        break;
      default:
        return undefined;
    }
  };

  const defineMessageType = (messageType: string) => {
    switch (messageType) {
      case "follow":
        return <NewFollow notification={notification} />;
        break;
      case "newPost":
        return <NewPost notification={notification} />;
        break;
      case "invite":
        return <NewIvitation notification={notification} />;
        break;
      case "newParticipate":
        return <NewParticipate notification={notification} />;
        break;
      case "postLike":
        return <NewPostLike notification={notification} />;
        break;
      case "newMessage":
        return <NewMessage notification={notification} />;
        break;
      default:
        return undefined;
    }
  };

  const refViewed = useRef(false);
  const [ref, isVisible] = useVisibility<HTMLDivElement>(containerRef);

  useEffect(() => {
    if (isVisible && !refViewed.current) {
      refViewed.current = true;
      onViewNotification(notification._id);
    }
  }, [isVisible]);

  return (
    <li>
      <Link to="#">
        <div className="dropdown-wrapper__content">
          <div className="nav-notification__type">
            {defineIconType(notification.notificationType)}
          </div>
          <div className="nav-notification__details">
            {defineMessageType(notification.notificationType)}
            <div ref={ref} className="nav-notification__details-status">
              <Badge dot />
            </div>
          </div>
        </div>
      </Link>
    </li>
  );
};

const NewParticipate: FC<NotificationInnerProps> = ({ notification }) => {
  const { t } = useTranslation();
  return (
    <div className="nav-notification__details-single">
      <div className="nav-notification__details-single-text">
        <span>{getUserFullName(notification.userFrom)}</span>{" "}
        {t("common.activity.newParticipate.title")}
        <Link to={`/event/${notification.entityId}`}>
          {" "}
          {t("common.activity.newParticipate.action")}
        </Link>
      </div>
      <p className="time">{getTimeAgo(notification.createdAt)}</p>
    </div>
  );
};

const NewPostLike: FC<NotificationInnerProps> = ({ notification }) => {
  const { t } = useTranslation();
  return (
    <div className="nav-notification__details-single">
      <div className="nav-notification__details-single-text">
        <span>{getUserFullName(notification.userFrom)}</span>{" "}
        {t("common.activity.newPostLike.title")}
        <Link to={`/event/${notification.entityId}`}>
          {" "}
          {t("common.activity.newPostLike.action")}
        </Link>
      </div>
      <p className="time">{getTimeAgo(notification.createdAt)}</p>
    </div>
  );
};

const NewPost: FC<NotificationInnerProps> = ({ notification }) => {
  const { t } = useTranslation();
  return (
    <div className="nav-notification__details-single">
      <div className="nav-notification__details-single-text">
        <span>{getUserFullName(notification.userFrom)}</span>{" "}
        {t("common.activity.newPost.title")}
        <Link to={`/event/${notification.entityId}`}>
          {" "}
          {t("common.activity.newPost.action")}
        </Link>
      </div>
      <p className="time">{getTimeAgo(notification.createdAt)}</p>
    </div>
  );
};

const NewFollow: FC<NotificationInnerProps> = ({ notification }) => {
  const { t } = useTranslation();
  return (
    <div className="nav-notification__details-single">
      <div className="nav-notification__details-single-text">
        <span>{getUserFullName(notification.userFrom)}</span>{" "}
        {t("common.activity.newFollow")}
      </div>
      <p className="time">{getTimeAgo(notification.createdAt)}</p>
    </div>
  );
};

const NewMessage: FC<NotificationInnerProps> = ({ notification }) => {
  const { t } = useTranslation();
  return (
    <div className="nav-notification__details-single">
      <div className="nav-notification__details-single-text">
        <span>{getUserFullName(notification.userFrom)}</span>{" "}
        {t("common.activity.newMessage")}
      </div>
      <p className="time">{getTimeAgo(notification.createdAt)}</p>
    </div>
  );
};

const NewIvitation: FC<NotificationInnerProps> = ({ notification }) => {
  const { t } = useTranslation();
  return (
    <div className="nav-notification__details-single">
      <div className="nav-notification__details-single-text">
        <span>{getUserFullName(notification.userFrom)}</span> invited you to an
        <Link to={`/admin/profile/myProfile/invitations`}> event</Link>
      </div>
      <p className="time">{getTimeAgo(notification.createdAt)}</p>
    </div>
  );
};
