import React from "react";
import { useTranslation } from "react-i18next";
import { Form, Input, Select, Tag } from "antd";
import { CATEGORIES, ECategories } from "@app/helpers/categories";

const { CheckableTag } = Tag;

type CategoriesFieldProps = {
  initValue?: string[];
};


const CATEGORIES$ = CATEGORIES.filter((tag) => tag.value !== ECategories.RESET)


const CategoriesField: React.FC<CategoriesFieldProps> = ({ initValue }) => {
  const { t } = useTranslation();

  const [selectedTags, setSelectedTags] = React.useState<string[]>(initValue || []);

  return (
    <Form.Item
      name="categories"
      label="Categories"
      initialValue={initValue}
    >
      <Select
        mode="multiple"
        value={selectedTags}
        onChange={setSelectedTags}
      >
        {CATEGORIES$.map(tag => (
          <Select.Option key={tag.value} value={tag.value}>{tag.text}</Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};
export default CategoriesField;
