import $api from "@app/helpers/http";
import { AxiosResponse } from "axios";
import { LastMessagesResponse } from "./notifications/NotificarionResponse";

export default class ChatService {
  static async fetchChats(): Promise<AxiosResponse<any, any>> {
    return $api.get("/chat/all");
  }

  static async fetchChatById(id: string): Promise<AxiosResponse<any, any>> {
    return $api.get(`/chat/${id}`);
  }

  static async fetchChatMessages(id: string): Promise<AxiosResponse<any, any>> {
    return $api.get(`/chat/${id}/messages`);
  }

  static async sendMessage(
    message: string,
    chatId: string
  ): Promise<AxiosResponse<any, any>> {
    return $api.post(`/messages`, { content: message, chatId });
  }

  static async messageByIdRequest(
    userId: string
  ): Promise<AxiosResponse<any, any>> {
    return $api.get(`/messages/${userId}`).then((res) => res.data);
  }

  static async markAllMessagesAsRead(
    chatId: string
  ): Promise<AxiosResponse<any, any>> {
    return $api({
      method: "PUT",
      url: `/chat/${chatId}/messages/markAsRead`,
    });
  }

  static async fetchLastMessagesRequest(): Promise<AxiosResponse<any, any>> {
    return $api.get<LastMessagesResponse>(`/messages/latest`);
  }
}
