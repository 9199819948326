import React from "react";
import ReactDOM from "react-dom";
import App from "./app";
import "./helpers/i18n/config";
import "antd/dist/antd.css";

ReactDOM.render(<App />, document.getElementById("root"));

if ("serviceWorker" in navigator && "PushManager" in window) {
  navigator.serviceWorker
    .register("../service-worker.js")
    .then(function (registration) {
      console.log("Service Worker registered with scope:", registration.scope);
    })
    .catch(function (error) {
      console.error("Service Worker registration failed:", error);
    });
}
