import $api from "@app/helpers/http";
import { NotificationDTOExtend } from "@app/models/dtos/NotificationDto";
import {
  LastMessagesResponse,
  NotificationResponse,
} from "./NotificarionResponse";

export async function fetchNotificationsRequest() {
  return await $api
    .get<NotificationDTOExtend[]>("/notifications")
    .then((res) => res.data);
}

export async function fetchLastNotificationsRequest() {
  return await $api
    .get<NotificationResponse>("/notifications/latest")
    .then((res) => res.data);
}

export async function fetchLastMessagesRequest() {
  return await $api
    .get<LastMessagesResponse>("/notifications/latest/messages")
    .then((res) => res.data);
}

export async function markAsOpenedNotificationsByIdRequest(id: string) {
  return await $api.put(`/notifications/${id}/markAsOpened`);
}

export async function markAsOpenedAllNotificationRequest() {
  return await $api.put(`/notifications/markAsOpened`);
}

export async function deleteNotificationByIdRequest(id: string) {
  return await $api({
    method: "DELETE",
    url: `/notifications/${id}`,
  });
}
