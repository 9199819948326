import Styled from "styled-components";

const BasicFormWrapper: any = Styled.div`
    .ant-form {
        .form-item{
            margin-bottom: 30px;
            label{
                font-weight: 500;
                display: block;
                margin-bottom: 15px;
                color: ${({ theme }) => theme[theme.mainContent]["gray-text"]};
            }
            .ant-cascader-picker{
                width: 100%;
                min-height: 48px;
                .ant-cascader-input{
                    min-height: 48px;
                }
            }
        }
        .ant-input-affix-wrapper > input.ant-input{
            padding-top: 12px;
            padding-bottom: 12px;
        }
        .ant-input-affix-wrapper .ant-input-prefix svg{
            color: #9299B8;
        }
        .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
            position: relative;
            top: -2px;
        }
        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus,
        textarea:-webkit-autofill,
        textarea:-webkit-autofill:hover,
        textarea:-webkit-autofill:focus,
        select:-webkit-autofill,
        select:-webkit-autofill:hover,
        select:-webkit-autofill:focus{
            background-color: ${({ theme }) =>
              theme[theme.mainContent]["white-background"]} !important;
        }
    }
    .ant-form-item-control-input{
        min-height: auto !important;
    }
    .ant-form-item,
    .ant-form-item-row{
        flex-flow: column;
        &:not(:last-child){
            margin-bottom: 26px;
        }
        &:last-child{
            margin-bottom: 0;
        }
        .ant-form-item-label{
            text-align: ${({ theme }) => (theme.rtl ? "right" : "left")};
            label{
                color: ${({ theme }) => theme[theme.mainContent]["dark-text"]};
                height: fit-content;
                margin-bottom: 6px;
            }
        }
        .ant-form-item-control-input{
            input,
            textarea{
                color: ${({ theme }) => theme[theme.mainContent]["gray-text"]};
                background-color: ${({ theme }) =>
                  theme[theme.mainContent]["input-bg"]};
                border-color: ${({ theme }) =>
                  theme[theme.mainContent]["border-color-secondary"]};
                &:placeholder{
                    color: ${({ theme }) =>
                      theme[theme.mainContent]["light-text"]};
                }
            }
            .ant-picker-input input{
                padding: 12px;
            }
            button{
                height: 44px;
            }
            .ant-input-affix-wrapper{
                padding: 0 20px;
            }
        }
        .ant-select {
            .ant-select-arrow{
                svg{
                    color: ${({ theme }) =>
                      theme[theme.mainContent]["gray-text"]};
                }
            }
            .ant-select-selection-search{
                display:flex;
                align-items: center;
            }
        }
        .ant-select-single{
            .ant-select-selector{
                padding: 0 20px;
                height: 50px !important;
                background-color: ${({ theme }) =>
                  theme[theme.mainContent]["input-bg"]};
                border-color: ${({ theme }) =>
                  theme[theme.mainContent]["border-color-default"]} !important;
                .ant-select-selection-item{
                    color: ${({ theme }) =>
                      theme[theme.mainContent]["gray-text"]};
                    line-height: 46px !important;
                    padding: 0 !important;
                }
                .ant-select-selection-placeholder{
                    line-height: 46px !important;
                }
            }
        }
        .ant-radio-group {
            color: ${({ theme }) => theme[theme.mainContent]["gray-text"]};
        }
    }
    .setting-form-actions{
        margin: 48px 0 14px;
        @media only screen and (max-width: 575px){
            margin: 40px 0 14px;
        }
        button{
            border-radius: 6px;
            height: 44px;
            margin-bottom: 14px;
            &.ant-btn-light{
                border: 1px solid ${({ theme }) =>
                  theme[theme.mainContent]["border-color-default"]};
                background-color: ${({ theme }) =>
                  theme[theme.mainContent]["main-background-light"]};
                color: ${({ theme }) => theme[theme.mainContent]["gray-text"]};
            }
        }
    }
    .ant-form-item-control-input{
        .input-prepend{
            position: absolute;
            ${({ theme }) => (theme.rtl ? "right" : "left")}: 0;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 0 20px;
            height: 48px;
            border-radius: ${({ theme }) =>
              theme.rtl ? "0 4px 4px 0" : "4px 0 0 4px"};
            z-index: 10;
            border: 1px solid ${({ theme }) =>
              theme[theme.mainContent]["border-color-secondary"]};
            background-color: ${({ theme }) =>
              theme[theme.mainContent]["main-background-light"]};
            svg,
            i{
                color:${({ theme }) => theme[theme.mainContent]["gray-text"]};
            }
            svg{
                width: 16px;
                height: 16px;
            }
        }
        .input-prepend-wrap{
            .ant-input-number{
                input{
                    ${({ theme }) =>
                      !theme.rtl ? "padding-left" : "padding-right"}: 70px;
                }
            }
        }
        .ant-input-number{
            width: 100% !important;
            border: 1px solid ${({ theme }) =>
              theme[theme.mainContent]["border-color-secondary"]};
            background-color: ${({ theme }) =>
              theme[theme.mainContent]["input-bg"]};
        }
    }
    .add-record-form{
        margin: 25px 0 35px 0;
        
        .record-form-actions{
            padding-right: 40px;
        }
        .ant-btn{
            height: 44px;
            font-size: 14px;
            font-weight: 500;
        }
        .ant-radio-group{
            margin-bottom: -4px;
            .ant-radio-wrapper{
                margin-bottom: 4px;
            }
        }
    }
    .adTodo-form{
        .btn-adTodo {
            font-size: 14px;
        }
    }

    .pllace-form-action{
        margin: -7.5px;
        button{
            font-size: 14px;
            font-weight: 500;
            border-radius: 6px;
            margin: 7.5px;
            padding: 6.4px 19px;
            &.ant-btn-light{
                height: 44px;
                color: ${({ theme }) => theme[theme.mainContent]["gray-text"]};
                background-color: ${({ theme }) =>
                  theme[theme.mainContent]["border-color-default"]};
                border-color: ${({ theme }) =>
                  theme[theme.mainContent]["border-color-default"]};
            }
        }
        .ant-form-item{
            margin-bottom: 25px !important;
        }
        .ant-btn-light{
            background-color: ${({ theme }) =>
              theme[theme.mainContent]["main-background"]};
        }
    }
    .pllace_color-picker{
        border: 1px solid ${({ theme }) =>
          theme[theme.mainContent]["border-color-secondary"]};
        border-radius: 4px;
        padding: 11px 14px;
        input{
            width: 100%;
            border: 0 none;
            background-color: #fff;
            &::-webkit-color-swatch{
                min-height: 18px;
                border: 1px solid #C6D0DC;
            }
        }
    }
    .ant-input-number{
        .ant-input-number-input{
            min-height: 46px;
        }
    }
`;

export { BasicFormWrapper };
