import $api from "@app/helpers/http";
import { AxiosResponse } from "axios";
import { AuthProfileResponse } from "./AuthResponse";

export default class UserService {
  static async fetchProfile(): Promise<AxiosResponse> {
    return $api.get<AuthProfileResponse>("/auth/my");
  }

  static async updateProfile(params: any): Promise<AxiosResponse> {
    return $api.patch<AuthProfileResponse>("/profile/my", params);
  }

  static async uploadPhote(file: FormData): Promise<AxiosResponse> {
    return $api.post("/profile/upload", file, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  // static async fetchUsers(): Promise<AxiosResponse> {
  //   return $api.get("/users");
  // }

  static async fetchAvatar(): Promise<AxiosResponse> {
    return $api.get("/profile/avatar");
  }

  static async deleteAvatar(params: any): Promise<AxiosResponse> {
    return $api({
      method: "DELETE",
      url: "/profile/avatar",
      data: {
        ...params,
      },
    });
  }
}
