import React, { FC, ReactNode } from "react";
import { Link } from "react-router-dom";
import { CardFrame } from "./style";
import { Dropdown } from "../../UIElements/dropdown/dropdown";
import Heading from "../../heading/heading";
import { UilEllipsisH } from "@iconscout/react-unicons";

interface CardsProps {
  title?: ReactNode;
  size?: string;
  more?: ReactNode;
  bodyStyle?: any;
  headStyle?: ReactNode;
  isbutton?: ReactNode;
  headless?: boolean;
  border?: boolean;
  caption?: string;
  bodypadding?: string;
  className?: string;
  moreText?: boolean;
  children?: ReactNode;
}

const Cards: FC<CardsProps> = ({
  title,
  children,
  more,
  moreText,
  size,
  headless,
  caption,
  isbutton,
  bodyStyle,
  headStyle,
  border = false,
  bodypadding,
  className,
}) => {
  return (
    <>
      {!headless ? (
        <CardFrame
          size={size}
          title={title}
          bodyStyle={bodyStyle && bodyStyle}
          headStyle={headStyle && headStyle}
          bordered={border}
          className={className}
          bodypadding={bodypadding && bodypadding}
          extra={
            <>
              {more && (
                <Dropdown content={more} placement="bottom">
                  <Link onClick={(e) => e.preventDefault()} to="#">
                    {!moreText ? <UilEllipsisH /> : "More"}
                  </Link>
                </Dropdown>
              )}

              {isbutton && isbutton}
            </>
          }
          style={{ width: "100%" }}
        >
          {children}
        </CardFrame>
      ) : (
        <CardFrame
          bodypadding={bodypadding && bodypadding}
          bodyStyle={bodyStyle && bodyStyle}
          size={size}
          style={{ width: "100%" }}
          bordered={border}
          className={className}
        >
          {title && <Heading as="h4">{title}</Heading>}
          {caption && <p>{caption}</p>}
          {children}
        </CardFrame>
      )}
    </>
  );
};

export { Cards };
