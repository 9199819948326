import { ConfigProvider, Spin } from "antd";
import React, { useEffect, useState, Suspense } from "react";
import { Provider } from "react-redux";
import { useAppDispatch, useAppSelector } from "./store/redux/store";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { store } from "./store/redux/store";
import { socketX } from "@app/store/mobx";

//TODO refactor -> delete styled-components
import { ThemeProvider } from "styled-components";
import config from "./helpers/config/config";
const { themeColor } = config;

import Admin from "./routes/admin";
import Auth from "./routes/auth/auth";
import Public from "./routes/public";
import Chat from "./routes/chat";

import {
  ProtectedAdminRoute,
  ProtectedAuthRoute,
} from "./components/protectedRoute/protectedRoute";
import { checkAuthAsync } from "./store/redux/authentication";
import { getLocalUserId, getToken } from "./helpers/jwtHelper";

import "./static/css/style.scss";
import { ChatProvider } from "./store/provider";

import $api, { socket } from "@app/helpers/http";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import notifications from "./store/mobx/notifications";
import axios from "axios";
import { UserDTO } from "./models/dtos/UserDto";

const ProviderConfig: React.FC = observer(() => {
  const myId = getLocalUserId();
  const dispatch = useAppDispatch();
  const { mainContent, isLoggedIn, isActivated, myProfileInfo } =
    useAppSelector((state) => {
      return {
        isLoggedIn: state.auth.isAuth,
        isActivated: state.auth.user.isActivated,
        mainContent: state.themeLayout.mode,
        myProfileInfo: state.profile.user,
      };
    });

  const onlineUsers = toJS(socketX.getOnlineUsers());

  useEffect(() => {
    if (getToken()) {
      dispatch(checkAuthAsync());
    }
  }, []);

  React.useEffect(() => {
    socket.on("get-users", (users) => {
      socketX.setOnlineUsers(users);
    });
    socket.on("message received", (users) => {
      setTimeout(() => notifications.fetchLastMessages(), 400);
    });
    socket.on("new notification", () => {
      setTimeout(() => notifications.fetchLastNotifications(), 400);
    });
    return () => {
      socket.emit("offline");
    };
  }, []);

  React.useEffect(() => {
    if (myId) {
      socket.emit("online", myId);
      requestNotificationPermission(myId);
    } else {
      socket.emit("offline");
    }
  }, [myId]);

  return (
    <ConfigProvider direction={"ltr"}>
      <ThemeProvider theme={{ ...themeColor, mainContent }}>
        <ChatProvider
          onlineUsers={onlineUsers}
          myProfile={myProfileInfo as UserDTO}
        >
          <Router>
            <Routes>
              <Route
                path="/auth/*"
                element={
                  <ProtectedAuthRoute
                    isLoggedIn={isLoggedIn}
                    isActivated={isActivated}
                  >
                    <Auth />
                  </ProtectedAuthRoute>
                }
              />
              <Route
                path="/admin/*"
                element={
                  <ProtectedAdminRoute
                    isLoggedIn={isLoggedIn}
                    isActivated={isActivated}
                  >
                    <Admin />
                  </ProtectedAdminRoute>
                }
              />
              <Route
                path="/chat"
                element={
                  <ProtectedAdminRoute
                    isLoggedIn={isLoggedIn}
                    isActivated={isActivated}
                  >
                    <Chat />
                  </ProtectedAdminRoute>
                }
              />
              <Route path="/*" element={<Public />} />
            </Routes>
          </Router>
        </ChatProvider>
      </ThemeProvider>
    </ConfigProvider>
  );
});

function App() {
  return (
    <Provider store={store}>
      <Suspense fallback={<Spin />}>
        <ProviderConfig />
      </Suspense>
    </Provider>
  );
}

export default App;

function requestNotificationPermission(userId: string) {
  if ('Notification' in window) {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        navigator.serviceWorker.ready.then((swReg) => {
          swReg.pushManager
            .subscribe({
              userVisibleOnly: true,
              applicationServerKey: process.env.VAPID_PUBLIC_KEY,
            })
            .then(async (subscription) => {
              await $api.post("/push", { subscription, userId });
            });
        });
      }
    });
  }
}
