import React from "react";
import Likes from "./likes/likes";
import Views from "./views/views";
import "./meta.scss";
import { EventResponse as Event } from "@app/services/event/EventResponse";
import { PostId } from "@app/models/dtos/PostDto";

type MetaProps = {
  eventInfo: Event;
  onLike: (eventId: PostId) => void;
  onView: (eventId: PostId) => void;
  userId: string;
};

const Meta: React.FC<MetaProps> = ({ eventInfo, onView, ...props }) => {
  return (
    <div className="meta__container">
      <Likes {...props} eventInfo={eventInfo} />
      <Views onView={onView} eventInfo={eventInfo} />
    </div>
  );
};
export default Meta;
