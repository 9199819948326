import MobileDatePicker from "@app/components/header/mobile/mobileDatePicker";
import React, { useEffect } from "react";
import "./sideBar.scss";
import event, { EventParams } from "@app/store/mobx/event";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Category, ECategories } from "@app/helpers/categories";
import UiCarousel from "@app/components/UIElements/carousel/carousel";

type SideBarProps = {
  toggleSidebar: () => void;
};

const SideBar: React.FC<SideBarProps> = ({ toggleSidebar }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();

  const categoryParam = searchParams.get("category") as ECategories;

  const onSubmit = (values: EventParams) => {
    toggleSidebar();
    if (pathname !== "/") {
      navigate("/");
    }
    setTimeout(() => event.fetchAllEvents(values), 1000);
  };

  const handleCategory = (category: Category) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.set('category', category.value);

    if (category.value === ECategories.RESET) {
      searchParams.delete('category')
      setSearchParams(searchParams)
      return
    }

    setSearchParams(newSearchParams);
  }

  useEffect(() => {
    if (categoryParam) {
      event.fetchAllEvents({ categories: categoryParam });
    }
    else {
      event.fetchAllEvents();
    }
  }, [categoryParam]);

  return (
    <div className="sidebar-content">
      <div className="filter-mobile">
        <UiCarousel activeCategory={categoryParam} onSetCategory={handleCategory} />
      </div>
      <MobileDatePicker onSubmit={onSubmit} />
    </div>
  );
};
export default SideBar;
