import $api from "@app/helpers/http";
import { getQueriedUrl } from "@app/helpers/queryStringHelper";
import { ICreateEditEventPlace } from "@app/models/Event";
import {
  EventParticipateResponse,
  EventResponse,
  EventsResponseExtend,
  LikesResponse,
  ParticipateResponse,
  StatusError,
  StatusSuccess,
  ViewResponse,
} from "@app/services/event/EventResponse";
import { PostDTO, PostId } from "@app/models/dtos/PostDto";
import { EventParams } from "@app/store/mobx/event";
import { UploadFile } from "antd/lib/upload/interface";
import { AxiosResponse } from "axios";
import { UserId } from "@app/models/dtos/UserDto";
import { LatLngTuple } from "leaflet";

export async function uploadFilesRequest(
  files: any,
  eventId: string
): Promise<AxiosResponse<StatusSuccess, StatusError>> {
  return await $api.post(`/event/${eventId}/upload`, files, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function removeFilesRequest(
  files: UploadFile[],
  eventId: string
): Promise<AxiosResponse<StatusSuccess, StatusError>> {
  return await $api({
    method: "DELETE",
    url: `/event/${eventId}/upload`,
    data: files,
  });
}

export async function createEventRequest(
  event: FormData
): Promise<AxiosResponse<PostDTO, any>> {
  return await $api.post("/event", event);
}

export async function editEventRequestById(
  event: Partial<ICreateEditEventPlace>,
  id: string
): Promise<AxiosResponse<204, any>> {
  return await $api.put(`/event/${id}`, event);
}

export async function deleteEventRequestById(
  id: string
): Promise<AxiosResponse<202, any>> {
  return await $api.delete(`/event/${id}`);
}

export async function fetchMyEventsRequest(
  params: EventParams
): Promise<AxiosResponse<EventsResponseExtend, any>> {
  return await $api.get(
    getQueriedUrl({
      url: "/event/my",
      query: params,
    })
  );
}

export async function fetchMyEventsForWidgetRequest(): Promise<
  AxiosResponse<EventsResponseExtend, any>
> {
  return await $api.get(`/event/widget/my`);
}

export async function fetchEventsForWidgetByUserIdRequest(
  id: string
): Promise<AxiosResponse<EventsResponseExtend, any>> {
  return await $api.get(`/event/widget/${id}`);
}

export async function onLikeRequestById(
  id: string
): Promise<AxiosResponse<LikesResponse, any>> {
  return await $api.put(`/event/${id}/like`);
}

export async function onViewRequestById(
  id: string
): Promise<AxiosResponse<ViewResponse, any>> {
  return await $api.put(`/event/${id}/view`);
}

export async function getParticipantsRequest(
  id: string
): Promise<AxiosResponse<EventParticipateResponse, any>> {
  return await $api.get(`/event/${id}/participants`);
}

export async function deleteParticipantRequest(
  eventId: string,
  userId: string
): Promise<AxiosResponse<EventParticipateResponse, any>> {
  return await $api.put(`/event/${eventId}/participant/${userId}`);
}

export async function participateRequest(
  id: string
): Promise<AxiosResponse<ParticipateResponse, any>> {
  return await $api.put(`/event/${id}/participate`);
}

export async function inviteRequest(
  users: UserId[],
  postId: PostId
): Promise<AxiosResponse<ParticipateResponse, any>> {
  return await $api.post(`/invitations`, { recipientIds: users, postId });
}

export async function fetchMyInvitetionsRequest(): Promise<
  AxiosResponse<any, any>
> {
  return await $api.get(`/invitations/my`);
}

export async function fetchStatusesOfInvitationRequest(
  postId: string
): Promise<AxiosResponse<any, any>> {
  return await $api.get(`/invitations/${postId}/statuses`);
}

export async function respondToInvitationRequest(
  invitationId: string,
  status: string
): Promise<AxiosResponse<any, any>> {
  return await $api.post(`/invitations/${invitationId}/respond`, { status });
}

export async function revokeInvitationFromUserRequest(
  postId: string,
  userId: string
): Promise<AxiosResponse<any, any>> {
  return await $api({
    method: "DELETE",
    url: `/invitations/${postId}/user`,
    data: { userId },
  });
}

export async function fetchEventByIdRequest(
  id: string
): Promise<AxiosResponse<EventResponse, any>> {
  return await $api.get(`/event/${id}`);
}

export async function fetchAllEventsRequest(
  params?: EventParams
): Promise<
  AxiosResponse<{ events: EventResponse[]; geoCoordinates: LatLngTuple }, any>
> {
  return await $api.get(
    getQueriedUrl({
      url: "/event/all",
      query: params,
    })
  );
}
