import { runInAction, makeAutoObservable } from "mobx";
import _ from "lodash";
import {
  fetchNotificationsRequest,
  fetchLastNotificationsRequest,
  fetchLastMessagesRequest,
  deleteNotificationByIdRequest,
  markAsOpenedNotificationsByIdRequest,
  markAsOpenedAllNotificationRequest,
} from "@app/services/notifications/NotificationService";
import { NotificationDTOExtend } from "@app/models/dtos/NotificationDto";
import { LastMessages } from "@app/services/notifications/NotificarionResponse";
import ChatService from "@app/services/ChatService";
import { UserId } from "@app/models/dtos/UserDto";

class Notifications {
  notificationsState: NotificationDTOExtend[] = [];

  lastNotificationsState: NotificationDTOExtend[] = [];
  countNotificationsState = 0;

  lastMessagesState: LastMessages[] = [];
  countMessagesState = 0;

  constructor() {
    this.notificationsState = [];
    makeAutoObservable(this);
  }

  get getNotifications() {
    return this.notificationsState;
  }

  cleanNotifications = () => {
    this.notificationsState = [];
  };

  deleteNotifications = (targetId: string) => {
    this.notificationsState = _.filter(
      this.notificationsState,
      (el) => el._id !== targetId
    );
  };

  async fetchNotifications() {
    const notifications = await fetchNotificationsRequest();
    runInAction(() => {
      this.notificationsState = notifications;
    });
  }

  async fetchLastNotifications() {
    const { lastNotifications, count } = await fetchLastNotificationsRequest();
    runInAction(() => {
      this.lastNotificationsState = lastNotifications;
      this.countNotificationsState = count;
    });
  }

  async fetchLastMessages() {
    const { data } = await ChatService.fetchLastMessagesRequest();
    runInAction(() => {
      this.lastMessagesState = data.lastMessages;
      this.countMessagesState = data.count;
    });
  }

  async openNotificationById(id: string) {
    return await markAsOpenedNotificationsByIdRequest(id);
  }

  async markAsReadAllNotification() {
    return await markAsOpenedAllNotificationRequest();
  }

  deleteNotificationById = async (id: string) => {
    await deleteNotificationByIdRequest(id);
    runInAction(() => {
      this.deleteNotifications(id);
    });
  };
}

export default new Notifications();
