import React, { FC, ReactNode, useEffect } from "react";
import "./participants.scss";
import { Alert, Avatar, Badge, Modal, Row, Col, Table } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { event } from "@app/store/mobx";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { Cards } from "@app/components/cards/frame/cards-frame";
import {
  TableWrapper,
  UserTableStyleWrapper,
} from "../inviteFollowings/InviteFollowings";
import { getUserAvatar, getUserFullName } from "@app/helpers/utils/user";
import { useNavigate } from "react-router-dom";
import Heading from "@app/components/heading/heading";
import { Button } from "@app/components/UIElements/buttons/buttons";
import { getLocalUserId } from "@app/helpers/jwtHelper";

type ParticipantsProps = {
  children?: ReactNode;
  count: number;
  eventId: string;
};

const Participants: React.FC<ParticipantsProps> = observer(
  ({ children, count, eventId }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const participants = toJS(event.eventParticipants) || [];
    

    useEffect(() => {
      if (eventId) {
        event.getEventParticipants(eventId);
      }
    }, [eventId]);

    return (
      <>
        <div className="participants">
          <HeaderLine title={t("common.participants")} eventId={eventId} />
          <div className="participants__content">
            <div className="participants__content-people">
              {participants.slice(0, 5).map((user: any) => {

                const img = getUserAvatar(user);
                const id = user?._id ? user._id : null;

                return (
                  <>
                    <div className="participants__content-person" onClick={() => navigate(`/profile/${id}`)}>
                      <img src={img} alt=""></img>
                    </div>
                  </>
                )
              })
              }
              {participants.length > 5 && (<BadgeParticipants count={count - 5} />)}
            </div>
            {children}
          </div>
          <Alert
            className="mt-20"
            message={t("common.notification.participantsChat")}
            type="info"
            showIcon
          />
        </div>
      </>
    );
  }
);
export default Participants;

type HeaderLineProps = {
  title: string;
  className?: string;
  eventId?: string;
};

export const HeaderLineEmpty: FC<HeaderLineProps> = ({title, className}) => {
  return (
    <div className={"participants__header " + className}>
    <div className="participants__header-title">
      {title}:
    </div>
    <div className="participants__header-under-line"></div>
  </div>
  )
}

export const HeaderLine: React.FC<HeaderLineProps> = ({ title, className, eventId }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const participants = toJS(event.eventParticipants) || [];
  const eventPostedBy = toJS(event.eventPostedBy);
  const myId = getLocalUserId();

  const showParticipants = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const usersTableData: any = [];
  const usersTableColumns = [
    {
      title: "User",
      dataIndex: "user",
      key: "name",
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: "90px",
    },
  ];

  participants.map((user: any) => {
    const name = getUserFullName(user);
    const img = getUserAvatar(user);
    const id = user?._id ? user._id : null;

    const onDelete = async () => {
      if (eventId) {
        event.deleteParticipantFromEvent({
          userId: user._id,
          postId: eventId,
        });
      }
    };

    return usersTableData.push({
      key: id,
      user: (
        <div className="user-info" onClick={() => navigate(`/profile/${id}`)}>
          <figure>
            <img style={{ width: "40px" }} src={img} alt="" />
          </figure>
          <figcaption>
            <Heading className="user-name" as="h6">
              {name}
            </Heading>
          </figcaption>
        </div>
      ),
      action: myId === eventPostedBy && id !== myId && (
        <Button
          onClick={onDelete}
          className="btn-icon"
          type="info"
          to="#"
          shape="circle"
        >
          Delete
        </Button>
      ),
    });
  });

  return (
    <>
      <div className={"participants__header " + className} onClick={showParticipants}>
        <div className="participants__header-title">
          {title}:
          <div className="participants__header-badge">
            <Badge count={participants.length} />
          </div>
        </div>
        <div className="participants__header-under-line"></div>
      </div>
      <Modal className="ant-p-0" open={isModalOpen} onCancel={closeModal} footer={null}>
        <Row gutter={25}>
          <Col md={24} xs={24}>
            <Cards headless>
              <UserTableStyleWrapper>
                <div className="contact-table mt-25">
                  <TableWrapper className="table-responsive">
                    <Table
                      style={{ overflowX: "auto" }}
                      dataSource={usersTableData}
                      columns={usersTableColumns}
                      pagination={{
                        defaultPageSize: 15,
                        total: usersTableData.length,
                        showTotal: (total, range) =>
                          `${range[0]}-${range[1]} of ${total} items`,
                      }}
                    />
                  </TableWrapper>
                </div>
              </UserTableStyleWrapper>
            </Cards>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

type BadgeParticipantsProps = {
  count: number;
  eventId?: string;
};

export const BadgeParticipants: React.FC<BadgeParticipantsProps> = observer(
  ({ count, eventId }) => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const participants = toJS(event.eventParticipants) || [];
    const eventPostedBy = toJS(event.eventPostedBy);
    const myId = getLocalUserId();

    const showParticipants = () => {
      setIsModalOpen(true);
    };

    const closeModal = () => {
      setIsModalOpen(false);
    };

    useEffect(() => {
      if (eventId) {
        event.getEventParticipants(eventId);
      }
    }, [eventId]);

    const usersTableData: any = [];
    const usersTableColumns = [
      {
        title: "User",
        dataIndex: "user",
        key: "name",
      },
      {
        title: "",
        dataIndex: "action",
        key: "action",
        width: "90px",
      },
    ];

    participants.map((user: any) => {
      const name = getUserFullName(user);
      const img = getUserAvatar(user);
      const id = user?._id ? user._id : null;

      const onDelete = async () => {
        if (eventId) {
          event.deleteParticipantFromEvent({
            userId: user._id,
            postId: eventId,
          });
        }
      };

      return usersTableData.push({
        key: id,
        user: (
          <div className="user-info" onClick={() => navigate(`/profile/${id}`)}>
            <figure>
              <img style={{ width: "40px" }} src={img} alt="" />
            </figure>
            <figcaption>
              <Heading className="user-name" as="h6">
                {name}
              </Heading>
            </figcaption>
          </div>
        ),
        action: myId === eventPostedBy && id !== myId && (
          <Button
            onClick={onDelete}
            className="btn-icon"
            type="info"
            to="#"
            shape="circle"
          >
            Delete
          </Button>
        ),
      });
    });

    return (
      <>
        <Badge size={"default"} count={count}>
          <Avatar
            onClick={showParticipants}
            shape="square"
            icon={<UserOutlined size={14} />}
          />
        </Badge>
        <Modal className="ant-p-0" open={isModalOpen} onCancel={closeModal} footer={null}>
          <Row gutter={25}>
            <Col md={24} xs={24}>
              <Cards headless>
                <UserTableStyleWrapper>
                  <div className="contact-table mt-25">
                    <TableWrapper className="table-responsive">
                      <Table
                        style={{ overflowX: "auto" }}
                        dataSource={usersTableData}
                        columns={usersTableColumns}
                        pagination={{
                          defaultPageSize: 15,
                          total: usersTableData.length,
                          showTotal: (total, range) =>
                            `${range[0]}-${range[1]} of ${total} items`,
                        }}
                      />
                    </TableWrapper>
                  </div>
                </UserTableStyleWrapper>
              </Cards>
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
);
