import { runInAction, makeAutoObservable } from "mobx";
import {
  createEventRequest,
  uploadFilesRequest,
  fetchMyEventsRequest,
  onLikeRequestById,
  onViewRequestById,
  deleteEventRequestById,
  editEventRequestById,
  removeFilesRequest,
  participateRequest,
  fetchAllEventsRequest,
  fetchEventByIdRequest,
  fetchEventsForWidgetByUserIdRequest,
  fetchMyEventsForWidgetRequest,
  inviteRequest,
  fetchMyInvitetionsRequest,
  respondToInvitationRequest,
  fetchStatusesOfInvitationRequest,
  revokeInvitationFromUserRequest,
  getParticipantsRequest,
  deleteParticipantRequest,
} from "@app/services/event/EventService";
import _ from "lodash";
import { fetchEventsByUserIdRequest } from "@app/services/profile/ProfileService";
import { EventResponse } from "@app/services/event/EventResponse";
import { UploadFile } from "antd/lib/upload/interface";
import { ICreateEditPlace } from "@app/models/Event";
import { PostId } from "@app/models/dtos/PostDto";
import { UserId } from "@app/models/dtos/UserDto";
import { LatLngTuple } from "leaflet";
import { ECategories } from "@app/helpers/categories";

export interface EventParams {
  userId?: UserId;
  status?: EventStatus;
  page?: number;
  location?: string;
  endDate?: Date;
  startDate?: Date;
  categories?: ECategories;
}

export type EventStatus = "all" | "active" | "completed" | "my";

export type SelectedCity = {
  city: string;
  city_ascii: string;
  lat: number;
  lng: number;
  country: string;
  iso2: string;
  iso3: string;
  admin_name: string;
  capital: string;
  population: number;
  id: number;
};

export type EventParticipant = {
  name: string;
  lastname: string;
  avatarFile: string;
};

class Events {
  eventsState: EventResponse[] = [];
  eventState: EventResponse = {} as EventResponse;
  eventsForWidget: EventResponse[] = [];
  eventsStatus: EventStatus = "all";
  eventPaginationPage = 1;
  eventPaginationTotal = 10;
  eventPageSize = 5;
  updates = 0;
  hoveredEvent: PostId = "";

  invitationsState: any[] = [];

  eventParticipants: EventParticipant[] = [];
  eventPostedBy: UserId = "";

  geoCoordinates: LatLngTuple = [44.80401, 20.46513]; // beograd
  selectedCity: SelectedCity | null = null;

  constructor() {
    this.eventsStatus = "all";
    this.eventsState = [];
    this.updates = 0;
    makeAutoObservable(this);
  }

  setSelectedCity(city: SelectedCity) {
    this.selectedCity = city;
  }

  getFocucedEvent = () => {
    return this.hoveredEvent;
  };

  clearEventState = () => {
    this.eventState = {} as EventResponse;
  };

  clearEventsState = () => {
    this.eventsState = {} as EventResponse[];
  };

  updateState = () => {
    this.updates = this.updates + 1;
  };

  setEventsStatus = (status: EventStatus) => {
    this.eventsStatus = status;
    this.eventPaginationPage = 1;
  };

  // setEventList = (apiData: any) => {
  //   this.eventsState = apiData;
  // };

  setPaginationPage = (page: number) => {
    this.eventPaginationPage = page;
  };

  deleteEventList = (eventId: string) => {
    this.eventsState = _.filter(this.eventsState, (el) => el._id !== eventId);
  };

  onHoverEvent = (id: PostId) => {
    this.hoveredEvent = id;
  };

  updatePostLikes = (targetId: string, newLikes: UserId[]) => {
    let updatedPosts = _.cloneDeep(this.eventsState);

    const index = _.findIndex(this.eventsState, { _id: targetId });
    if (index !== -1) {
      this.eventsState = _.set(updatedPosts, `[${index}].likes`, newLikes);
    }
    if (!_.isEmpty(this.eventState)) {
      this.eventState.likes = newLikes;
    }
  };

  updatePostParticipates = (targetId: string, newParticipates: UserId[]) => {
    let updatedPosts = _.cloneDeep(this.eventsState);

    const index = _.findIndex(this.eventsState, { _id: targetId });
    if (index !== -1) {
      this.eventsState = _.set(
        updatedPosts,
        `[${index}].participants`,
        newParticipates
      );
    }

    if (!_.isEmpty(this.eventState)) {
      this.eventState.participants = newParticipates;
    }
  };

  // addEventList = (event: any) => {
  //   this.eventsState.push(event);
  // };

  updateEventsState = (id: string, diff: any) => {
    this.eventsState = this.eventsState.map((event) =>
      event._id === id ? { ...event, ...diff } : event
    );
  };

  async uploadFiles(files: any, eventId: string) {
    const newEventFiles = await uploadFilesRequest(files, eventId);
    return newEventFiles;
  }

  async removeFiles(files: UploadFile[], eventId: string) {
    return await removeFilesRequest(files, eventId);
  }

  createEvent = async (event: FormData) => {
    const { data } = await createEventRequest(event);
    runInAction(() => {
      this.updateState();
    });
    return data;
  };

  editEvent = async (event: Partial<ICreateEditPlace>, id: string) => {
    const { data } = await editEventRequestById(event, id);
    runInAction(() => {
      this.updateState();
    });
    return data;
  };

  createPlace = async (event: FormData) => {
    const { data } = await createEventRequest(event);
    return data;
  };

  async deleteEvent(id: string) {
    deleteEventRequestById(id).then(() => this.deleteEventList(id));
  }

  async fetchMyEvents(params: EventParams) {
    const { data } = await fetchMyEventsRequest(params);
    runInAction(() => {
      this.eventsState = data.data;
      this.eventPaginationTotal = data.size;
    });
  }

  async fetchMyEventsForWidget() {
    const { data } = await fetchMyEventsForWidgetRequest();
    runInAction(() => {
      this.eventsForWidget = data.data;
    });
  }

  async fetchEventsForWidgetByUserId(id: string) {
    const { data } = await fetchEventsForWidgetByUserIdRequest(id);
    runInAction(() => {
      this.eventsForWidget = data.data;
    });
  }

  async fetchEventsByUserId(params: EventParams) {
    const { data } = await fetchEventsByUserIdRequest(params);
    runInAction(() => {
      this.eventsState = data.data;
      this.eventPaginationTotal = data.size;
    });
  }

  async fetchEventById(id: string) {
    const event = await fetchEventByIdRequest(id);
    runInAction(() => {
      this.eventState = event.data;
    });
  }

  async fetchAllEvents(params?: EventParams) {
    const params$ = {
      ...params,
      location: this.selectedCity
        ? this.selectedCity.id.toString()
        : params?.location,
    };
    const events = await fetchAllEventsRequest(params$);
    runInAction(() => {
      this.eventsState = events.data.events;
      if (events.data.geoCoordinates) {
        this.geoCoordinates = events.data.geoCoordinates;
      }
    });
  }

  onLike = async (id: string) => {
    const { data } = await onLikeRequestById(id);
    runInAction(() => {
      this.updatePostLikes(data.postId, data.likes);
    });
  };

  async onView(id: string) {
    const { data } = await onViewRequestById(id);
    runInAction(() => {
      // this.updateEventsState(id, { ...data });
    });
  }

  getEventParticipants = async (id: string) => {
    const { data } = await getParticipantsRequest(id);
    runInAction(() => {
      this.eventParticipants = data.participants;
      this.eventPostedBy = data.postedBy;
    });
  };

  deleteParticipantFromEvent = async ({
    postId,
    userId,
  }: {
    postId: string;
    userId: string;
  }) => {
    const { data } = await deleteParticipantRequest(postId, userId);
    runInAction(() => {
      this.eventParticipants = data.participants;
      this.eventPostedBy = data.postedBy;
    });
  };

  participate = async (id: string) => {
    const { data } = await participateRequest(id);
    runInAction(() => {
      this.updatePostParticipates(data.postId, data.participants);
    });
  };

  invite = async (users: UserId[], postId: PostId) => {
    const { data } = await inviteRequest(users, postId);
    runInAction(() => {
      // this.updatePostParticipates(data.postId, data.participants);
    });
  };

  fetchMyInvitetions = async () => {
    const { data } = await fetchMyInvitetionsRequest();
    runInAction(() => {
      this.eventsState = data.data;
      this.eventPaginationTotal = data.size;
    });
  };

  fetchStatusesOfInvitation = async (postId: PostId) => {
    const { data } = await fetchStatusesOfInvitationRequest(postId);
    runInAction(() => {
      this.invitationsState = data;
    });
  };

  respondToInvitation = async (invitationId: string, status: string) => {
    await respondToInvitationRequest(invitationId, status);
  };

  revokeInvitationFromUser = async (postId: PostId, userId: UserId) => {
    await revokeInvitationFromUserRequest(postId, userId);
  };
}

export default new Events();
