import { event } from "@app/store/mobx";
import { toJS } from "mobx";
import React from "react";
import { Card, Typography } from "antd";
import { getPostFirstImage, getPostTitle } from "@app/helpers/utils/posts";
import Meta from "../post/meta/meta";
import { getLocalUserId } from "@app/helpers/jwtHelper";
import "./mapEvent.scss";
import { observer } from "mobx-react-lite";
import { UilMultiply } from "@iconscout/react-unicons";
import modals from "@app/store/mobx/modals";
import { Link } from "react-router-dom";
const { Text } = Typography;

type MapEventProps = {
  isMobile: boolean;
};

const MapEvent: React.FC<MapEventProps> = observer(({ isMobile }) => {
  const eventInfo = toJS(event.eventState);
  const userId = getLocalUserId();

  const postImg = getPostFirstImage(eventInfo);

  const onCloseMapEvent = () => {
    modals.onCloseMapEvent();
    event.onHoverEvent("");
  };

  return (
    <Card
      style={{ width: 310, height: 100 }}
      bodyStyle={{ padding: 0 }}
      className={`map-event ${isMobile ? "" : " isDesctope"}`}
    >
      <Link to={`/event/${eventInfo._id}`}>
        <div className={"map-event__content"}>
          <img width={100} height={100} src={postImg} alt="event-img" />
          <div className={"map-event__content-text"}>
            <Text>{getPostTitle(eventInfo)}</Text>
            <Meta
              eventInfo={eventInfo}
              userId={userId}
              onLike={() => {}}
              onView={() => {}}
            />
          </div>
        </div>
      </Link>
      <UilMultiply onClick={onCloseMapEvent} className={"close"} size={14} />
    </Card>
  );
});
export default MapEvent;
