import React, { useState } from "react";
import { Footer } from "../style";
import { Button } from "@app/components/UIElements/buttons/buttons";
import { UilMessage } from "@iconscout/react-unicons";
import { ChatContext } from "@app/store/provider";
import * as _ from "lodash";
import { socket } from "@app/helpers/http";
import { useTranslation } from "react-i18next";
import { PushNotificationGroupType } from "@app/services/push/PushService";

type SendProps = {
  scrollToBottom: () => void;
  chatId: string;
  penPalInfoId: string;
  myFullName: string | null;
};

const Send: React.FC<SendProps> = ({
  scrollToBottom,
  chatId,
  penPalInfoId,
  myFullName,
}) => {
  const { t } = useTranslation();
  const {
    fetchChatMessages,
    sendMessage,
    sendPushNotificationFromChat,
  } = React.useContext(ChatContext);

  const [chatMessage, setChatMessage] = useState<string>("");

  const handleInputChange = (e: any) => {
    setChatMessage(e.target.value);
    socket.emit("typing", chatId);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const isPush = chatMessage.includes("@push");

    if (chatMessage.trim() !== "") {
      sendMessage(chatMessage, chatId)
        .then(() => socket.emit("new message", chatId))
        .then(() =>
          socket.emit("send message to", {
            to: penPalInfoId,
            from: myFullName,
            message: chatMessage,
            chatId: chatId,
            isPush,
          })
        )
        .then(() => fetchChatMessages(chatId))
        .then(() => scrollToBottom());
      setChatMessage("");

      if (isPush) {
        const payload = {
          type: PushNotificationGroupType.PRIVATE,
          fromName: myFullName || "unknown",
          message: chatMessage.replace("@push", ""),
          userIds: [penPalInfoId],
          chatId: chatId,
        };
        sendPushNotificationFromChat(payload);
      }
    }
  };

  React.useEffect(() => {
    const timer = setTimeout(() => socket.emit("stop typing", chatId), 2000);
    return () => clearTimeout(timer);
  }, [chatMessage]);

  return (
    <Footer>
      <form onSubmit={handleSubmit}>
        <div className={`chatbox-reply-form d-flex`}>
          <div className="chatbox-reply-input">
            <input
              onChange={handleInputChange}
              placeholder={t("chat.typeYourMessage") as string}
              name="chat"
              id="chat"
              style={{ width: "100%" }}
              value={chatMessage}
            />
          </div>
          <div className="chatbox-reply-action d-flex">
            <Button onClick={handleSubmit} type="primary" className="btn-send">
              <UilMessage />
            </Button>
          </div>
        </div>
      </form>
    </Footer>
  );
};
export default Send;
