import React, { FC, useEffect } from "react";
import { Row, Col, Table } from "antd";
import { useNavigate } from "react-router-dom";
import { Cards } from "@app/components/cards/frame/cards-frame";

import { Button } from "@app/components/UIElements/buttons/buttons";
import { event, profile } from "@app/store/mobx";
import { toJS } from "mobx";

import Styled from "styled-components";
import Heading from "@app/components/heading/heading";
import {
  getUserFullName,
  getUserAvatar,
  getUsername,
} from "@app/helpers/utils/user";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { UserId } from "@app/models/dtos/UserDto";
import { PostId } from "@app/models/dtos/PostDto";

interface InviteFollowingsProps {
  postId: PostId;
}

export const InviteFollowings: FC<InviteFollowingsProps> = observer(
  ({ postId }) => {
    if (!postId) {
      return null;
    }

    const { t } = useTranslation();
    const followers = toJS(profile.followers);
    const statusesOfInvitation = toJS(event.invitationsState) || [];
    const navigate = useNavigate();

    const [invitedList, setInvitedList] = React.useState<React.Key[]>([]);
    const usersTableData: any = [];

    followers?.map((user: any) => {
      const name = getUserFullName(user);
      const userName = getUsername(user);
      const img = getUserAvatar(user);
      const id = user?._id ? user._id : null;

      const invitation = statusesOfInvitation?.find(
        (inv) => inv.recipient === user._id
      );
      const status = invitation?.status;

      const onRevoke = async () => {
        await event.revokeInvitationFromUser(postId, id);
        event.fetchStatusesOfInvitation(postId);
      };

      return usersTableData.push({
        key: id,
        user: (
          <div className="user-info" onClick={() => navigate(`/profile/${id}`)}>
            <figure>
              <img style={{ width: "40px" }} src={img} alt="" />
            </figure>
            <figcaption>
              <Heading className="user-name" as="h6">
                {name}
              </Heading>
              <span className="user-designation">{userName}</span>
            </figcaption>
          </div>
        ),
        status,
        action: status && (
          <Button
            onClick={onRevoke}
            className="btn-icon"
            type="info"
            to="#"
            shape="circle"
          >
            Revoke
          </Button>
        ),
      });
    });

    const usersTableColumns = [
      {
        title: "User",
        dataIndex: "user",
        key: "name",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
      },
      {
        title: "",
        dataIndex: "action",
        key: "action",
        width: "90px",
      },
    ];

    useEffect(() => {
      profile.fetchMyFollowers();
      event.fetchStatusesOfInvitation(postId);
    }, []);

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
      setInvitedList(newSelectedRowKeys);
    };

    const rowSelection = {
      invitedList,
      onChange: onSelectChange,
      getCheckboxProps: (record: any) => ({
        disabled: !!record.status, // Отключить чекбокс, если status определен
      }),
    };

    const onInvite = async () => {
      await event.invite(invitedList as UserId[], postId);
      event.fetchStatusesOfInvitation(postId);
    };

    return (
      <>
        <Row gutter={25}>
          <Col md={24} xs={24}>
            <Cards headless>
              <UserTableStyleWrapper>
                <div className="contact-table">
                  <TableWrapper className="table-responsive">
                    <Table
                      style={{ overflowX: "auto" }}
                      rowSelection={rowSelection}
                      dataSource={usersTableData}
                      columns={usersTableColumns}
                      pagination={{
                        defaultPageSize: 15,
                        total: usersTableData.length,
                        showTotal: (total, range) =>
                          `${range[0]}-${range[1]} of ${total} items`,
                      }}
                    />
                  </TableWrapper>
                </div>
              </UserTableStyleWrapper>
              <Button
                width="full"
                size="default"
                onClick={onInvite}
                type="primary"
              >
                Invite
              </Button>
            </Cards>
          </Col>
        </Row>
      </>
    );
  }
);

export const UserTableStyleWrapper = Styled.nav`
  table{
    .ant-table-cell {
      text-align: left;
    }
    tbody{
      td{
        .user-info{
          .user-name{
            font-size: 14px;
            width: max-content;
          }
        }
        span.status-text{
          font-size: 12px;
          padding: 0 12.41px;
          line-height: 1.9;
          font-weight: 500;
          border-radius: 12px;
          text-transform: capitalize;
          display: inline-block !important;
          background: #ddd;
          &.active{
            background-color: ${({ theme }) => theme["success-color"]}15;
            color: ${({ theme }) => theme["success-color"]};
          }
          &.deactivate{
            background-color: ${({ theme }) => theme["warning-color"]}15;
            color: ${({ theme }) => theme["warning-color"]};
          }
          &.blocked{
            background-color: ${({ theme }) => theme["danger-color"]}15;
            color: ${({ theme }) => theme["danger-color"]};
          }
        }
      }
    }
  }
  .ant-table-pagination.ant-pagination{
    width: 100%;
    text-align: ${({ theme }) => (!theme.rtl ? "right" : "left")};
    border-top: 1px solid ${({ theme }) =>
      theme[theme.mainContent]["border-color-default"]};
    margin-top: 0 !important;
    align-items: center;
    padding-top: 30px;
    @media only screen and (max-width: 767px){
      text-align: center;
    }
  }
  .contact-table{
    table{
      tr{
        th,
        td{
          &:first-child{
            ${({ theme }) =>
              theme.rtl ? "padding-right" : "padding-left"}: 20px;
          }
          &:last-child{
            ${({ theme }) =>
              theme.rtl ? "padding-left" : "padding-right"}: 20px;
          }
        }
      }
      .table-actions{
        button{
          width: 32px;
          height: 32px;
          padding: 0;
          background-color: transparent;
          &:hover{
            background-color: transparent;
          }
          &.ant-btn-primary{
            &:hover{
              color: #ADB4D2;
            }
          }
        }
      }
      tbody >tr.ant-table-row-selected >td{
        background-color: ${({ theme }) =>
          theme[theme.mainContent]["primary-background"]}10;
      }
    }
  }
`;

export const TableWrapper = Styled.div`
    .ant-pagination-prev, .ant-pagination-next {
        line-height: 28px !important;
        transform: rotateY(${({ theme }) => (theme.rtl ? "180deg" : "0deg")})
    }
    .ant-table table{
        text-align: ${({ theme }) => (!theme.rtl ? "left" : "right")};
    }
    .ant-table-thead > tr > th{
        text-align: ${({ theme }) => (!theme.rtl ? "left" : "right")};
    }
    span.anticon.anticon-right{
        transform: rotateY(${({ theme }) => (theme.rtl ? "180deg" : "0deg")})
    }
    span.anticon.anticon-left{
        transform: rotateY(${({ theme }) => (theme.rtl ? "180deg" : "0deg")})
    }
    .ant-table{
        background-color: ${({ theme }) =>
          theme[theme.mainContent]["white-background"]};
    }
    &.table-order,
    &.table-seller,
    &.table-data-view{
        .ant-table-selection{
            .ant-checkbox-indeterminate{
                .ant-checkbox-inner{
                    background: ${({ theme }) => theme["primary-color"]};
                    border-color: ${({ theme }) => theme["primary-color"]};
                    &:after{
                        height: 2px;
                        background-color: ${({ theme }) =>
                          theme[theme.mainContent]["white-background"]};
                    }
                }
            }
        }
        .ant-table-container{
            padding-bottom: 25px;
            border-bottom: 1px solid ${({ theme }) =>
              theme[theme.mainContent]["border-color-default"]};
        }
        tbody{
            tr{
                &:hover{
                    td{
                        background: ${({ theme }) =>
                          theme[theme.mainContent]["main-background"]};
                    }
                }
                td{
                    .product-id{
                        max-width: 60px;
                        text-align: ${({ theme }) =>
                          theme.rtl ? "left" : "right"};
                    }
                }
            }
        }
        .ant-pagination{
            margin-top: 25px !important;
        }
    }
    &.table-data-view{
        .ant-table-container{
            padding-bottom: 15px;
        }
        table{
            thead{
                th{
                    padding: 16px 25px;
                }
            }
            tbody{
                td{
                    padding: 16px 25px;
                    .record-img{
                        img{
                            max-width: 38px;
                            width: 38px;
                            height: 38px;
                            border-radius: 50%;
                            ${({ theme }) =>
                              theme.rtl ? "margin-left" : "margin-right"}: 12px;
                        }
                    }
                    .record-location{
                        display: block;
                        font-size: 12px;
                        font-weight: 400;
                        color: ${({ theme }) =>
                          theme[theme.mainContent]["light-text"]};
                    }
                    .status{
                        font-weight: 500;
                        text-transform: capitalize;
                        &.active{
                            color: ${({ theme }) => theme["success-color"]};
                            background: ${({ theme }) =>
                              theme["success-color"]}10;
                        }
                        &.deactivated{
                            color: ${({ theme }) => theme["warning-color"]};
                            background: ${({ theme }) =>
                              theme["warning-color"]}10;
                        }
                        &.blocked{
                            color: ${({ theme }) => theme["danger-color"]};
                            background: ${({ theme }) =>
                              theme["danger-color"]}10;
                        }
                    }
                    .table-actions{
                        a{
                            svg, i{
                                width: 16px;
                                color: ${({ theme }) =>
                                  theme[theme.mainContent]["extra-light-text"]};
                            }
                            &.edit{
                              &:hover{
                                svg,
                                i{
                                    color: ${({ theme }) =>
                                      theme["info-color"]};
                                }
                              }  
                            }
                            &.delete{
                              &:hover{
                                svg,
                                i{
                                    color: ${({ theme }) =>
                                      theme["danger-color"]};
                                }
                              }  
                            }
                        }
                    }
                }
            }
        }
    }
    &.table-responsive{
        table{
            tbody{
                tr{
                    &:hover{
                        td{
                            background: ${({ theme }) =>
                              theme[theme.mainContent]["white-background"]};
                        }
                    }
                }
            } 
        }
    }
    table{
        thead{
            tr{
                border-radius: 10px;
                th{
                    border-bottom: 0 none;
                    &:first-child{
                        border-radius: 10px 0 0 10px !important;
                    }
                    &:last-child{
                        border-radius: 0 10px 10px 0 !important;
                        text-align: "left";
                    }
                    color: ${({ theme }) =>
                      theme[theme.mainContent]["gray-text"]};
                    background: ${({ theme }) => theme["bg-color-light"]};
                }
            }
        }
        tbody{
            tr{
                &:hover{
                    td{
                        background: ${({ theme }) =>
                          theme[theme.mainContent]["white-background"]};
                    }
                }
                &.ant-table-row-selected{
                    &:hover{
                        td{
                            background: ${({ theme }) =>
                              theme[theme.mainContent]["white-background"]};
                        }
                    }
                    td{
                        background: ${({ theme }) =>
                          theme[theme.mainContent]["white-background"]};
                    }
                }
                td{
                    border: 0 none;
                    font-weight: 500;
                    color: ${({ theme }) =>
                      theme[theme.mainContent]["dark-text"]};
                    &:first-child{
                        border-radius: ${({ theme }) =>
                          !theme.rtl
                            ? "10px 0 0 10px"
                            : "0 10px 10px 0"} !important;
                    }
                    &:last-child{
                        border-radius: ${({ theme }) =>
                          !theme.rtl
                            ? "0 10px 10px 0"
                            : "10px 0 0 10px"} !important;
                    }
                    span{
                        display: block;
                    }
                    .order-id{
                        min-width: 128px;
                    }
                    .customer-name{
                        min-width: 174px;
                    }
                    .status{
                        min-width: 175px;
                    }
                    .ordered-amount{
                        min-width: 175px;
                    }
                    .ordered-date{
                        min-width: 165px;
                    }
                    .table-actions{
                        min-width: 60px;
                    }
                }
            }
        }
        .table-actions{
            text-align: ${({ theme }) => (theme.rtl ? "left" : "right")};
            min-width: 150px !important;
            button{
                height: 40px;
                padding: 0 11px;
                background: transparent;
                border: 0 none;
                color: ${({ theme }) =>
                  theme[theme.mainContent]["extra-light-text"]};
                &:hover{
                    &.ant-btn-primary{
                        color: ${({ theme }) => theme["primary-color"]};
                        background: ${({ theme }) => theme["primary-color"]}10;
                    }
                    &.ant-btn-info{
                        color: ${({ theme }) => theme["info-color"]};
                        background: ${({ theme }) => theme["info-color"]}10;
                    }
                    &.ant-btn-danger{
                        color: ${({ theme }) => theme["danger-color"]};
                        background: ${({ theme }) => theme["danger-color"]}10;
                    }
                }
            }
        }
        .seller-info{
            img{
                ${({ theme }) =>
                  theme.rtl ? "margin-left" : "margin-right"}: 12px;
            }
        }
        .user-info{
            display: flex;
            align-items: center;
            figure{
                margin: 0 8px 0;
            }
            .user-name{
                margin-bottom: 4px;
                font-weight: 500;
            }
            .user-designation{
                font-size: 12px;
                font-weight: 400;
                color: ${({ theme }) =>
                  theme[theme.mainContent]["gray-light-text"]};
            }
        }
    }
    .table-actions{
        margin: -8px;
        a{
            display: inline-block;
            margin: 8px;
        }
    }
    .pllace-status{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        text-transform: capitalize;
        font-weight: 500;
        padding: 0 8px;
        min-height: 24px;
        border-radius: 15px;
        &.pllace-status-active{
            color: ${({ theme }) => theme["success-color"]};
            background-color: ${({ theme }) => theme["success-color"]}15;
        }
        &.pllace-status-deactiveted{
            color: ${({ theme }) => theme["warning-color"]};
            background-color: ${({ theme }) => theme["warning-color"]}15;
        }
        &.pllace-status-blocked{
            color: ${({ theme }) => theme["danger-color"]};
            background-color: ${({ theme }) => theme["danger-color"]}15;
        }
    }
`;
