import PropTypes from "prop-types";
import React, { FC, ReactNode, useState } from "react";
import { ButtonStyled, ButtonStyledGroup } from "./styled";

interface ButtonpRrops {
  type:
    | "primary"
    | "secondary"
    | "success"
    | "info"
    | "warning"
    | "danger"
    | "link"
    | "dark"
    | "light"
    | "gray"
    | "white"
    | "dashed"
    | "error"
    | "extra-light"
    | "default";
  shape?: string;
  icon?: string;
  size?: string;
  color?: string;
  outlined?: boolean;
  transparented?: boolean;
  raised?: boolean;
  squared?: boolean;
  social?: boolean;
  load?: boolean;
  ghost?: boolean;
  children?: ReactNode;
  to?: any;
  htmlType?: any;
  onClick?: any;
  className?: string;
  block?: boolean;
  width?: "full" | string;
  ref?: any;
  form?: string;
  disabled?: boolean;
}

const Button: FC<ButtonpRrops> = (props) => {
  const {
    type,
    shape,
    icon,
    size,
    outlined,
    ghost,
    transparented,
    raised,
    squared,
    color,
    social,
    load,
    children,
    width,
    ...rest
  } = props;
  const [state, setState] = useState({
    loading: false,
  });

  const enterLoading = () => {
    setState({ loading: true });
  };

  return (
    <ButtonStyled
      squared={squared}
      outlined={outlined ? 1 : 0}
      ghost={ghost}
      transparent={transparented ? 1 : 0}
      raised={raised ? 1 : 0}
      data={type}
      size={size}
      shape={shape}
      type={type}
      icon={icon}
      color={color}
      social={social}
      width={width}
      onClick={load && enterLoading}
      loading={state.loading}
      {...rest}
    >
      {children}
    </ButtonStyled>
  );
};

Button.defaultProps = {
  type: "default",
};

interface BtnGroupProps {
  children: ReactNode;
}

const BtnGroup: FC<BtnGroupProps> = ({ children }) => {
  return <ButtonStyledGroup>{children}</ButtonStyledGroup>;
};

export { Button, BtnGroup };
