import Styled from "styled-components";

const GmapWraper: any = Styled.div`
    wieth: ${(props: any) => props.width}; 
    height: ${(props: any) => props.height};
    position: relative;
    overflow: hidden;
    .leaflet-container {
        wieth: ${(props: any) => props.width}; 
        height: ${(props: any) => props.height};
    }
    .leaflet-bar{
        border-color: ${({ theme }) =>
          theme[theme.mainContent]["border-color-default"]} !important;
        a,
        a:hover{
            color: ${({ theme }) => theme[theme.mainContent]["dark-text"]};
            background-color: ${({ theme }) =>
              theme[theme.mainContent]["white-background"]};
            border-color: ${({ theme }) =>
              theme[theme.mainContent]["border-color-default"]};
        }
        
    }
    .leaflet-popup-content-wrapper {
      background-color: ${({ theme }) =>
        theme[theme.mainContent]["white-background"]};
      border-radius: 4px;
    }
    .leaflet-popup-tip-container{
        display:none;
      }
`;

export { GmapWraper };
