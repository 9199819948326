import { BASE_API_URL } from "@app/helpers/http";
import { TCity } from "@app/models/dtos/UserDto";
import UserService from "@app/services/auth/ProfileService";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notification } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import * as _ from "lodash";

interface IsocialMedia {
  facebook: string;
  instagram: string;
  vk: string;
}

export interface IProfile {
  _id: string;
  name: string;
  lastname: string;
  phone: string;
  profession: string;
  userBio: string;
  username: string;
  email: string;
  socialMediaLinks?: IsocialMedia;
  city?: TCity;
}

interface ProfileState {
  user: IProfile;
  form: IProfile;
  avatarPhoto: UploadFile;
  loading: boolean;
}

const initialState: ProfileState = {
  user: {} as IProfile,
  avatarPhoto: {} as UploadFile,
  form: {} as IProfile,
  loading: false,
};

export const fetchProfileAsync = createAsyncThunk(
  "profile/fetchProfile",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await UserService.fetchProfile();
      return data;
    } catch (err) {
      notification.error({ message: "Ошибка получения профиля!" });
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateProfileAsync = createAsyncThunk(
  "profile/updateProfile",
  async (params: any, { rejectWithValue }) => {
    try {
      const { data } = await UserService.updateProfile(params);
      notification.success({ message: "Данные успешно изменены!" });
      return data;
    } catch (err) {
      notification.error({ message: "Ошибка при изменении данных" });
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchAvatarAsync = createAsyncThunk(
  "profile/fetchAvatar",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await UserService.fetchAvatar();
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const uploadPhoteAsync = createAsyncThunk(
  "profile/uploadPhote",
  async (file: FormData, { rejectWithValue }) => {
    try {
      const { data } = await UserService.uploadPhote(file);
      notification.success({ message: "Данные успешно отправлены!" });
      return data;
    } catch (err) {
      notification.error({ message: "Ошибка при отправке данных" });
      return rejectWithValue(err.response.data);
    }
  }
);

export const deletePhotoAsync = createAsyncThunk(
  "profile/deletePhoto",
  async (file: UploadFile, { rejectWithValue }) => {
    try {
      const { data } = await UserService.deleteAvatar(file);
      notification.success({ message: "Файл успешно удален!" });
      return data;
    } catch (err) {
      notification.error({ message: "Ошибка при удалении" });
      return rejectWithValue(err.response.data);
    }
  }
);

type TPayloadAction = {
  key: string;
  value: string;
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    updateProfileForm: (state, { payload }: PayloadAction<TPayloadAction>) => {
      state.form = {
        ...state.form,
        [payload.key]: payload.value,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfileAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProfileAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.user = payload.user;
        state.form = payload.user;
      })
      .addCase(fetchProfileAsync.rejected, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(updateProfileAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateProfileAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.user = payload.user;
      })
      .addCase(updateProfileAsync.rejected, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(uploadPhoteAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(uploadPhoteAsync.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(uploadPhoteAsync.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchAvatarAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAvatarAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (_.isEmpty(state.avatarPhoto)) {
          state.avatarPhoto = {
            ...payload,
            url: BASE_API_URL + payload.url,
          };
        }
      })
      .addCase(fetchAvatarAsync.rejected, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(deletePhotoAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(deletePhotoAsync.fulfilled, (state) => {
        state.loading = false;
        state.avatarPhoto = {} as UploadFile;
      })
      .addCase(deletePhotoAsync.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { updateProfileForm } = profileSlice.actions;

export default profileSlice.reducer;
