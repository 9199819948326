import { createSlice } from "@reduxjs/toolkit";
import {
  activateEmailAsync,
  changePasswordAsync,
  loginAsync,
  newPasswordAsync,
  registrationAsync,
  resetPasswordAsync,
  verificateEmailAsync,
} from "./authentication";
import { updateProfileAsync } from "./profile";

export interface formValidationState {
  hasError: boolean;
  errors: Array<any>;
  message: null | string;
}

const initialState: formValidationState = {
  hasError: false,
  errors: [],
  message: null,
};

export const formValidationSlice = createSlice({
  name: "formValidation",
  initialState,
  reducers: {
    clearFormvalidation(state) {
      state.hasError = false;
      state.message = null;
      state.errors = [];
    },
    addValidationError(state, { payload }) {
      state.hasError = true;
      state.message = payload?.message;
      state.errors = payload?.errors;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.hasError = false;
      })
      .addCase(registrationAsync.pending, (state) => {
        state.hasError = false;
      })
      .addCase(activateEmailAsync.pending, (state) => {
        state.hasError = false;
      })
      .addCase(verificateEmailAsync.pending, (state) => {
        state.hasError = false;
      })
      .addCase(changePasswordAsync.pending, (state) => {
        state.hasError = false;
      })
      .addCase(updateProfileAsync.pending, (state) => {
        state.hasError = false;
      })
      .addCase(resetPasswordAsync.pending, (state) => {
        state.hasError = false;
      })
      .addCase(loginAsync.rejected, (state, { payload }: any) => {
        state.hasError = true;
        state.message = payload?.message;
        state.errors = payload?.errors;
      })
      .addCase(registrationAsync.rejected, (state, { payload }: any) => {
        state.hasError = true;
        state.message = payload?.message;
        state.errors = payload?.errors;
      })
      .addCase(changePasswordAsync.rejected, (state, { payload }: any) => {
        state.hasError = true;
        state.message = payload?.message;
        state.errors = payload?.errors;
      })
      .addCase(updateProfileAsync.rejected, (state, { payload }: any) => {
        state.hasError = true;
        state.message = payload?.message;
        state.errors = payload?.errors;
      })
      .addCase(activateEmailAsync.rejected, (state, { payload }: any) => {
        state.hasError = true;
        state.message = payload?.message;
        state.errors = payload?.errors;
      })
      .addCase(verificateEmailAsync.rejected, (state, { payload }: any) => {
        state.hasError = true;
        state.message = payload?.message;
        state.errors = payload?.errors;
      })
      .addCase(resetPasswordAsync.rejected, (state, { payload }: any) => {
        state.hasError = true;
        state.message = payload?.message;
        state.errors = payload?.errors;
      })
      .addCase(newPasswordAsync.rejected, (state, { payload }: any) => {
        state.hasError = true;
        state.message = payload?.message;
        state.errors = payload?.errors;
      });
  },
});

export const { clearFormvalidation, addValidationError } =
  formValidationSlice.actions;

export default formValidationSlice.reducer;
