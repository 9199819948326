/**
 * @typedef {Object} token
 * @property {string} access - access token
 * @property {string} user - user id
 * @property {number} expires - access token expires time
 * @property {string} refresh - refresh token
 * @property {number} refresh_expires - refresh token expires time
 * @property {number} now - current time
 *
 */

export function saveToken(token: string) {
  if (token) localStorage.setItem("token", JSON.stringify(token));
}

export function getToken() {
  const strToken = localStorage.getItem("token");
  return strToken ? JSON.parse(strToken) : null;
}

export function removeToken() {
  localStorage.removeItem("token");
}

export function setToken(token: string) {
  if (token) localStorage.setItem("token", JSON.stringify(token));
}

export function setLocalUserId(id: string) {
  if (id) localStorage.setItem("userId", JSON.stringify(id));
}

export function removeLocalUserId() {
  localStorage.removeItem("userId");
}

export function getLocalUserId() {
  const user = localStorage.getItem("userId");
  return user ? JSON.parse(user) : null;
}
