import React from "react";
import { useTranslation } from "react-i18next";
import { Form, Input } from "antd";

type DescriptionFieldProps = {
  initValue?: string;
};

const DescriptionField: React.FC<DescriptionFieldProps> = ({ initValue }) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      name="description"
      label={t("common.forms.description")}
      initialValue={initValue}
      rules={[
        {
          required: true,
          message: t("common.validation.required") as string,
        },
      ]}
    >
      <Input.TextArea
        rows={4}
        placeholder={t("common.forms.eventDescription") as string}
      />
    </Form.Item>
  );
};
export default DescriptionField;
