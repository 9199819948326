import { Badge, Popover, Empty } from "antd";
import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { Link } from "react-router-dom";
import notificationsData from "@app/store/mobx/notifications";
import Heading from "@app/components/heading/heading";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import { getUserAvatar, getUserFullName } from "@app/helpers/utils/user";
import { getTimeAgo } from "@app/helpers/utils/date";
import _ from "lodash";
import { useTranslation } from "react-i18next";

const MessageBox = observer(() => {
  const { t } = useTranslation();
  const lastMessages = toJS(notificationsData.lastMessagesState);
  const countMessages = toJS(notificationsData.countMessagesState);

  const content = (
    <div className="dropdown-wrapper">
      <Heading className="dropdown-wrapper__title" as="h5">
        <span className="title-text">{t("common.header.newMessages")}</span>
        {!_.isEmpty(lastMessages) && (
          <Badge color="green" count={countMessages} />
        )}
      </Heading>
      <Scrollbars autoHeight autoHide>
        <ul>
          {!_.isEmpty(lastMessages) ? (
            lastMessages?.map((message) => {
              const unreadMessages = message?.count;
              const fullName = getUserFullName(message?.userFrom);
              const userAvatar = getUserAvatar(message?.userFrom);
              const timeAgo = getTimeAgo(message?.message?.createdAt);
              const lastMessage = message?.message?.content;

              return (
                <li>
                  <Link to={`/admin/chat/private/${message._id}`}>
                    <div className="dropdown-wrapper__content">
                      <div className="nav-message__type">
                        <img src={userAvatar} alt="" />
                      </div>
                      <div className="nav-message__details">
                        <div className="nav-message__details-top">
                          <span className="name">{fullName}</span>
                          <span className="time">{timeAgo}</span>
                        </div>
                        <div className="nav-message__details-bottom">
                          <span className="pllace-top-dropdownText">
                            {lastMessage}
                          </span>
                          <span>
                            <Badge color="green" count={unreadMessages} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </li>
              );
            })
          ) : (
            <div className="empty-content">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          )}
          <ul />
        </ul>
      </Scrollbars>
      <Link className="dropdown-wrapper__more" to="/admin/chat">
        {t("common.header.allMessages")}
      </Link>
    </div>
  );

  return (
    <Popover
      showArrow={false}
      zIndex={989}
      overlayClassName={"header__dropdown-custom-messages"}
      placement="bottomRight"
      content={content}
    >
      <Badge
        dot={countMessages ? true : false}
        className={countMessages ? "bell" : ""}
        color="green"
        offset={[1, 2]}
      >
        <Link to="#">
          <img src={require("@assets/svg/envelope.svg")} />
        </Link>
      </Badge>
    </Popover>
  );
});

export default MessageBox;
