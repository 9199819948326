import React, { FC, ReactNode } from "react";
import { Link } from "react-router-dom";
import { Content, DropdownStyle } from "./dropdown-style";

interface DropdownProps {
  placement?: string;
  title?: string;
  action?: string[];
  content?: ReactNode;
  children?: ReactNode;
  style?: ReactNode;
  className?: string;
  trigger?: string;
}

const Dropdown: FC<DropdownProps> = ({
  content = contentDefault,
  placement = "bottomRight",
  title,
  action = ["hover"],
  children,
  style = {},
  className = "pllace-dropdown",
}) => {
  return (
    <DropdownStyle
      overlayClassName={className}
      style={style}
      placement={placement}
      title={title}
      overlay={<Content>{content}</Content>}
      trigger={action}
    >
      {children}
    </DropdownStyle>
  );
};

const contentDefault = (
  <>
    <Link to="#">
      <span>Export to CSV</span>
    </Link>
    <Link to="#">
      <span>Export to XML</span>
    </Link>
    <Link to="#">
      <span>Export to Drive</span>
    </Link>
  </>
);

export { Dropdown };
