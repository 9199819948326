import { runInAction, makeAutoObservable } from "mobx";
import _ from "lodash";
import {
  fetchProfileByIdRequest,
  followByIdRequest,
  fetchFollowersByIdRequest,
  fetchFollowingByIdRequest,
  fetchMyProfileRequest,
  fetchMyFollowersRequest,
  fetchMyFollowingRequest,
} from "@app/services/profile/ProfileService";
import ChatService from "@app/services/ChatService";
import { ProfileDTO, UserDTO, UserId } from "@app/models/dtos/UserDto";
import { socket } from "@app/helpers/http";

class Profile {
  myProfile: UserDTO = {} as UserDTO;
  followers: UserDTO[] = [];
  following: UserDTO[] = [];

  profile: ProfileDTO = {} as ProfileDTO;

  constructor() {
    makeAutoObservable(this);
  }

  cleanUserProfile = () => {
    this.myProfile = {} as UserDTO;
    this.profile = {} as ProfileDTO;
    this.followers = [];
    this.following = [];
  };

  getMyProfileInfo = () => this.myProfile;

  deleteFollowing = (targetId: string) => {
    this.following = _.filter(this.following, (el) => el._id !== targetId);
  };

  async fetchMyProfile() {
    const { data } = await fetchMyProfileRequest();
    runInAction(() => {
      this.myProfile = data;
    });
  }

  async fetchProfileById(id: string) {
    const { data } = await fetchProfileByIdRequest(id);
    runInAction(() => {
      this.profile = data;
    });
  }

  followById = async (id: string) => {
    const { data } = await followByIdRequest(id);
    socket.emit("new notification to", id);
    runInAction(() => {
      this.profile.followers = data.followers;
      this.profile.isFollowing = data.isFollowing;
      // const updatedMyfollowing = this.myProfile?.following?.filter(
      //   (user) => user !== id
      // );
      this.myProfile.following = data.isFollowing
        ? this.myProfile.following + 1
        : this.myProfile.following - 1;
    });
  };

  async fetchMyFollowers() {
    const { data } = await fetchMyFollowersRequest();
    runInAction(() => {
      this.followers = data;
    });
  }

  async fetchMyFollowing() {
    const { data } = await fetchMyFollowingRequest();
    runInAction(() => {
      this.following = data;
    });
  }

  async fetchFollowers(id: string) {
    const { data } = await fetchFollowersByIdRequest(id);
    runInAction(() => {
      this.followers = data;
    });
  }

  async fetchFollowing(id: string) {
    const { data } = await fetchFollowingByIdRequest(id);
    runInAction(() => {
      this.following = data;
    });
  }

  // Todo delete from file

  async messageById(id: string) {
    return await ChatService.messageByIdRequest(id);
  }
}

export default new Profile();
