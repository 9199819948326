import ChatService from "@app/services/ChatService";
import * as React from "react";
import * as _ from "lodash";
import { getLocalUserId } from "@app/helpers/jwtHelper";
import { textRefactor } from "@app/helpers/utils/common";
import { OnlineUsers } from "../mobx/socket";
import { ChatId } from "@app/models/dtos/ChatDto";
import { UserDTO } from "@app/models/dtos/UserDto";
import { getUserFullName } from "@app/helpers/utils/user";
import {
  PushNotificationFromChatType,
  sendGroupNotificationRequest,
} from "@app/services/push/PushService";

interface ChatController {
  isLoading: boolean;
  isChatLoading: boolean;
  fetchChats: () => void;
  fetchChat: (chatId: string) => Promise<void>;
  fetchChatMessages: (chatId: string) => Promise<void>;
  getPrivateChats: () => any[];
  getGroupChats: () => any[];
  getChat: () => any;
  getMessages: () => any[];
  sendMessage: (message: string, chatId: string) => Promise<void>;
  getMyId: () => string | null;
  markAllMessagesAsRead: (chatId: string) => Promise<void>;
  getChatMembers: (chatId: string) => any[];
  getPostTitle: () => void;
  getPostId: () => string | undefined;
  getPenPalInfo: any;
  onlineUsers: OnlineUsers[];
  updatedChatsByKey: (targetId: ChatId, key: string) => void;
  updateLastMessageReadBy: any;
  cleanMessages: () => void;
  getMyFullName: () => string | null;
  sendPushNotificationFromChat: (
    payload: PushNotificationFromChatType
  ) => Promise<void>;
}

interface Props {
  children: React.ReactNode;
  onlineUsers: OnlineUsers[];
  myProfile: UserDTO;
}

export const Context = React.createContext({} as ChatController);

const ChatProvider: React.FC<Props> = ({
  children,
  onlineUsers,
  myProfile,
}) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [chatLoading, setChatLoading] = React.useState<boolean>(true);

  const [chats, setChats] = React.useState<any>([]);
  const [chat, setChat] = React.useState<any>();
  const [penPalInfo, setPenPal] = React.useState<any>();
  const [messages, setMessages] = React.useState<any>([]);

  const myId = getLocalUserId();

  const cleanMessages = () => setMessages([]);

  const fetchChats = async () => {
    setIsLoading(true);
    try {
      const chatsData = (await ChatService.fetchChats()).data;
      setChats(chatsData);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const updatedChatsByKey = (targetId: ChatId, key: string) => {
    const updatedChats = _.map(chats, (obj) =>
      obj._id == targetId ? _.omit(obj, key) : obj
    );
    setChats(updatedChats);
  };

  const fetchChat = async (chatId: string) => {
    setChatLoading(true);
    ChatService.fetchChatById(chatId)
      .then((chatData) => setChat(chatData.data))
      .finally(() => setChatLoading(false));
  };

  const fetchChatMessages = async (chatId: string) => {
    setIsLoading(true);
    try {
      const responseData = (await ChatService.fetchChatMessages(chatId)).data;
      const messageData = responseData.messages;
      setMessages(messageData);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const updateLastMessageReadBy = async (userId: string) => {
    if (userId) {
      const updatedMessages: any[] = _.cloneDeep(messages);
      const lastMessage = _.last(updatedMessages);

      if (lastMessage && lastMessage.readBy) {
        lastMessage.readBy.push(userId);
      }
      return updatedMessages;
    }
    return [];
  };

  const getPrivateChats = () => _.filter(chats, ["isGroupChat", false]);
  const getGroupChats = () => _.filter(chats, ["isGroupChat", true]);

  const getPenPalInfo = React.useCallback(() => {
    const user = _.chain(chat?.users)
      .filter((user) => user._id !== myId)
      .head()
      .value();
    return user;
  }, [chat]);

  const getPostTitle = () => {
    if (chat && chat.isGroupChat && chat.post) {
      return textRefactor(chat.post?.title, 25);
    }
    return "Групповой чат";
  };

  const getPostId = () => {
    if (chat && chat.isGroupChat && chat.post) {
      return chat.post?._id;
    }
    return undefined;
  };

  const getChatMembers = (chatId: string) =>
    _.chain(chats)
      ?.filter(["_id", chatId])
      .map((el) => el.users)
      .head()
      .value() || [];

  const sendMessage = async (message: string, chatId: string) => {
    setIsLoading(true);
    try {
      await ChatService.sendMessage(message, chatId);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const markAllMessagesAsRead = async (chatId: string) => {
    try {
      await ChatService.markAllMessagesAsRead(chatId);
    } catch (err) {
      console.log(err);
    }
  };

  const getMyFullName = () => getUserFullName(myProfile);

  React.useEffect(() => {
    if (chat?._id) {
      updatedChatsByKey(chat._id, "unread");
    }
  }, [chat, messages]);

  const sendPushNotificationFromChat = async (
    payload: PushNotificationFromChatType
  ) => {
    await sendGroupNotificationRequest(payload);
  };

  const controller = {
    isLoading,
    fetchChats,
    fetchChat,
    fetchChatMessages,
    getPrivateChats,
    getGroupChats,
    getChat: () => chat,
    getMessages: () => messages,
    sendMessage,
    getMyId: () => myId,
    markAllMessagesAsRead,
    getChatMembers,
    getPostTitle,
    getPostId,
    getPenPalInfo,
    onlineUsers,
    updatedChatsByKey,
    updateLastMessageReadBy,
    isChatLoading: chatLoading,
    cleanMessages,
    getMyFullName,
    sendPushNotificationFromChat,
  };

  return <Context.Provider value={controller}>{children}</Context.Provider>;
};

export default ChatProvider;
