import React from "react";
import moment from "moment";
import { MessageList } from "../style";
import Heading from "@app/components/heading/heading";
import { getUserFullName } from "@app/helpers/utils/user";
import { getLocalUserId } from "@app/helpers/jwtHelper";

type ChatMessageProps = {
  message: any;
  sender: any;
  lastMessageWasReaded: boolean;
  penPalId: string;
  isToday: boolean;
};

const ChatMessage: React.FC<ChatMessageProps> = ({
  message,
  sender,
  lastMessageWasReaded,
  penPalId,
  isToday,
}) => {
  const senderId = sender?._id || "";
  const myId = getLocalUserId();

  const isReadedMessage = (senderId: string, readBy: string[]) => {
    return readBy.includes(senderId);
  };

  return (
    <>
      <li className="atbd-chatbox__single" style={{ overflow: "hidden" }}>
        <div className={senderId !== myId ? "left" : "right"}>
          <div className="atbd-chatbox__content">
            <Heading as="h5" className="atbd-chatbox__name">
              {senderId !== myId && getUserFullName(sender)}
              <span>
                {isToday
                  ? moment(message.createdAt).format("HH:mm")
                  : moment(message.createdAt).format("HH:mm LL")}
              </span>
            </Heading>

            {senderId !== myId ? (
              <div className="atbd-chatbox__contentInner d-flex">
                <div className="atbd-chatbox__message">
                  <MessageList className="message-box">
                    {message.content}
                  </MessageList>
                </div>
              </div>
            ) : (
              <div className="atbd-chatbox__contentInner content-end">
                <div className="atbd-chatbox__message">
                  <MessageList className="message-box">
                    {message.content}
                  </MessageList>
                  <div className="message-seen text-right">
                    <span className="message-seen__time">
                      {isReadedMessage(penPalId, message.readBy) ||
                      lastMessageWasReaded ? (
                        <img src={require("@assets/svg/double-check.svg")} />
                      ) : (
                        <img src={require("@assets/svg/check.svg")} />
                      )}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </li>
    </>
  );
};
export default ChatMessage;
