import { createSlice } from "@reduxjs/toolkit";

export interface ThemeUsersState {
  name: string;
  email: string;
  mobile: string;
  id: number;
}

const initialState: ThemeUsersState[] = [
  {
    name: "woadud",
    email: "woaduda@gmail.com",
    mobile: "01742920502",
    id: 1,
  },
];

export const themeUsersSlice = createSlice({
  name: "themeUsers",
  initialState,
  reducers: {},
});

export const {} = themeUsersSlice.actions;

export default themeUsersSlice.reducer;
