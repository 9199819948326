import React from "react";
import { Link } from "react-router-dom";
import { Ti18Languages } from "@app/helpers/i18n/config";
import { Dropdown, MenuProps, Popover } from "antd";

type Props = {
  onFlagChangeHandle: (
    value: Ti18Languages,
    e: React.MouseEvent<HTMLAnchorElement>
  ) => void;
  flag: Ti18Languages;
};

const LanguageSelector: React.FC<Props> = ({ onFlagChangeHandle, flag }) => {
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <Link onClick={(e) => onFlagChangeHandle("en", e)} to="#">
          <img src={require("@app/static/img/flag/en.png")} alt="" />
          <span>English</span>
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link onClick={(e) => onFlagChangeHandle("ru", e)} to="#">
          <img src={require("@app/static/img/flag/ru.png")} alt="" />
          <span>Русский</span>
        </Link>
      ),
    },
  ];

  return (
    <Dropdown
      overlayClassName={"header__dropdown-custom-language"}
      menu={{ items }}
      placement="bottom"
    >
      <Link to="#">
        <img
          width="20"
          height={"20"}
          src={require(`@app/static/img/flag/${flag}.png`)}
          alt=""
        />
      </Link>
    </Dropdown>
  );
};
export default LanguageSelector;
