import _ from "lodash";
import { BASE_API_URL } from "../http";
import moment from "moment";
import { EventResponse } from "@app/services/event/EventResponse";
import { PostFilesDTO } from "@app/models/dtos/PostDto";

export const getPostFirstImage = (post: EventResponse) =>
  post && !_.isEmpty(post.images)
    ? BASE_API_URL + "images/" + post.images[0].path
    : require("@assets/imgs/avatar/profileImage.png");
export const getPostImage = (image: PostFilesDTO) =>
  image
    ? BASE_API_URL + "images/" + image.path
    : require("@assets/imgs/avatar/profileImage.png");

export const getPostTitle = (post: EventResponse) =>
  post && post?.title ? post.title : null;
export const getPostDescription = (post: EventResponse) =>
  post && post?.description ? post.description : null;

export const getPostStartDate = (post: EventResponse) =>
  post && post?.startDate ? moment(post.startDate).format("lll") : null;
export const getPostEndDate = (post: EventResponse) =>
  post && post?.endDate ? moment(post.endDate).format("lll") : null;
export const getPostShortStartDate = (post: EventResponse) =>
  post && post?.startDate
    ? moment(post.startDate).format("DD MMMM YYYY")
    : null;
export const getPostShortEndDate = (post: EventResponse) =>
  post && post?.endDate ? moment(post.endDate).format("DD MMMM YYYY") : null;

export const getPostViewLength = (post: EventResponse) =>
  post && post?.views ? post.views.length : 0;
export const getPostLikesLength = (post: EventResponse) =>
  post && post?.likes ? post.likes.length : 0;
export const getPostParticipantsLength = (post: EventResponse) =>
  post && post?.participants ? post.participants.length : 0;
