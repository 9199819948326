import { UserOutlined } from "@ant-design/icons";
import { Avatar, Popover, Space } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { UilAngleDown, UilSignin, UilUser } from "@iconscout/react-unicons";

const NoAuthUser: React.FC = () => {
  const { t } = useTranslation();

  const userContent = (
    <div className="dropdown-parent-wrapper">
      <div className="dropdown-wrapper">
        <div className="user-dropdown">
          <div className="nav-author__options">
            <ul>
              <li>
                <Link to="/auth/register">
                  <UilUser size={15} /> {t("auth.signUp")}
                </Link>
              </li>
              <li>
                <Link to="/auth">
                  <UilSignin size={15} /> {t("auth.signIn")}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <Popover
      zIndex={989}
      overlayClassName={"header__dropdown-custom-no-auth"}
      placement="bottomRight"
      content={userContent}
      trigger="click"
    >
      <Link to="#" className="nav-item-toggle align-center">
        <Space size={16} wrap>
          <Avatar icon={<UserOutlined size={12} />} />
        </Space>
        <UilAngleDown />
      </Link>
    </Popover>
  );
};

export default NoAuthUser;
