import { runInAction, makeAutoObservable, toJS } from "mobx";
import _ from "lodash";

type TEvent = "createEvent" | "createPlace" | "editEvent" | "viewEvent" | "showFollowers" | "showFollowing";

class Modals {
  createEvent: boolean = false;
  createPlace: boolean = false;
  scrollTop: number = 0;

  showFollowers: boolean = false;
  showFollowing: boolean = false;

  chatDrawer: boolean = false;
  sideBarDrawer: boolean = false;
  showMobileActions: boolean = false;

  mapEvent: boolean = false;

  constructor() {
    this.createEvent = false;
    this.createPlace = false;
    this.showMobileActions = false;
    makeAutoObservable(this);
  }

  onVisible = (type: TEvent) => {
    if (type === "createEvent") {
      this.createEvent = true;
    }
    if (type === "createPlace") {
      this.createPlace = true;
    }
    if (type === "showFollowers") {
      this.showFollowers = true;
    }
    if (type === "showFollowing") {
      this.showFollowing = true;
    }
    return;
  };

  onCancel = (type?: TEvent) => {
    if (type === "createPlace") {
      this.createPlace = false;
      return
    }
    if (type === "showFollowers") {
      this.showFollowers = false;
      return
    }
    if (type === "showFollowing") {
      this.showFollowing = false;
    }
    this.createEvent = false;
  };

  onVisibleChatDrawer = () => {
    this.chatDrawer = true;
  };

  onCloseChatDrawer = () => {
    this.chatDrawer = false;
  };

  toggleSidebar = () => {
    this.showMobileActions = false;
    this.sideBarDrawer = !this.sideBarDrawer;
  };

  onCloseSidebar = () => {
    this.sideBarDrawer = false;
  };

  toggleMobileActions = () => {
    this.showMobileActions = !this.showMobileActions;
    this.sideBarDrawer = false;
  };

  onShowMobileActions = () => {
    this.showMobileActions = true;
    this.sideBarDrawer = false;
  };

  onCloseMobileActions = () => {
    this.showMobileActions = false;
  };

  onScrollTop = () => {
    this.scrollTop = this.scrollTop + 1;
  };

  onShowMapEvent = () => {
    this.mapEvent = true;
  };

  onCloseMapEvent = () => {
    this.mapEvent = false;
  };
}

export default new Modals();
