import { Divider, Popover, notification } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Heading from "@app/components/heading/heading";
import {
  Uil0Plus,
  UilAngleDown,
  UilCopy,
  UilCreateDashboard,
  UilPlus,
  UilSetting,
  UilSignout,
  UilUser,
} from "@iconscout/react-unicons";
import i18n, { Ti18Languages } from "@app/helpers/i18n/config";
import { useAppDispatch, useAppSelector } from "@app/store/redux/store";
import { logoutAsync } from "@app/store/redux/authentication";
import modals from "@app/store/mobx/modals";
import { isAdminUser } from "@app/helpers/admins";

type Props = {};

const path = "/admin";

const AuthUser: React.FC<Props> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const language = i18n.language as Ti18Languages;

  const avatar = useAppSelector((state) => state.profile.avatarPhoto);
  const myId = useAppSelector((state) => state.profile.user._id);
  const avatarUrl = avatar?.url
    ? avatar.url
    : require("@assets/imgs/avatar/profileImage.png");


  const onShareID = () => {
    const LINK = `${process.env.DOMAIN}/profile/${myId}`;
    navigator.clipboard.writeText(LINK).then(() => {
      notification.success({ message: "Copied" });
    });
  };

  const SignOut = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    dispatch(logoutAsync());
    navigate("/");
  };

  const { fullName, profession } = useAppSelector((state) => {
    return {
      fullName: `${state.profile.user?.name} ${state.profile.user?.lastname}`,
      profession: state.profile?.user?.profession,
    };
  });

  const userContent = (
    <div className="dropdown-parent-wrapper author">
      <div className="dropdown-wrapper">
        <div className="user-dropdown">
          <div className="nav-author__info">
            <div className="author-img">
              <img src={avatarUrl} alt="" />
            </div>
            <div>
              <Heading className="m-0" as="h7">
                {fullName}
              </Heading>
              <span>{profession}</span>
            </div>
          </div>
          <div className="nav-author__options">
            <ul>
              <li>
                <Link to={`${path}/profile/myProfile`}>
                  <UilUser size={15} /> {t("common.profile")}
                </Link>
              </li>
              <li>
                <NavLink to='#' onClick={() => onShareID()}>
                  <UilCopy size={15} />  {t("common.accountSettings.userLink")}
                </NavLink>
              </li>
              <li>
                <Link to={`${path}/profile/settings`}>
                  <UilSetting size={15} /> {t("common.settings")}
                </Link>
              </li>
              <Divider style={{ margin: 0 }} />
              <li>
              <NavLink to='#' onClick={() => modals.onVisible("createEvent")}>
                <UilPlus size={15}/>
                {t("common.addNewEvent")}
              </NavLink>
              </li>
              {isAdminUser(myId) ? (
                <NavLink to='#' onClick={() => modals.onVisible("createPlace")}>
                  <UilCreateDashboard size={15} />  Add New Place
                </NavLink>
              ) : (null)}
            </ul>
            <Link className="nav-author__signout" onClick={SignOut} to="#">
              <UilSignout size={18} /> {t("auth.signOut")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <Popover
      zIndex={989}
      overlayClassName={"header__dropdown-custom-auth"}
      placement="bottomRight"
      content={userContent}
      trigger="click"
    >
      <Link to="#" className="nav-item-toggle d-flex">
        <span className="nav-item__title">{fullName}</span>
        <UilAngleDown />
      </Link>
    </Popover>
  );
};
export default AuthUser;
