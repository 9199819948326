import $api from "@app/helpers/http";
import { EventsResponseExtend } from "@app/services/event/EventResponse";
import { ProfileDTO, UserDTO, UserId } from "@app/models/dtos/UserDto";
import { AxiosResponse } from "axios";
import { EventParams } from "@app/store/mobx/event";
import { getQueriedUrl } from "@app/helpers/queryStringHelper";

export async function fetchMyProfileRequest(): Promise<
  AxiosResponse<UserDTO, any>
> {
  return await $api.get("/profile/my");
}

export async function fetchProfileByIdRequest(
  id: string
): Promise<AxiosResponse<ProfileDTO, any>> {
  return await $api.get(`/profile/${id}`);
}

type FollowByIdResponse = {
  followers: number;
  isFollowing: boolean;
};

export async function followByIdRequest(
  id: string
): Promise<AxiosResponse<FollowByIdResponse, any>> {
  return await $api.put(`/profile/${id}/follow`);
}

export async function fetchMyFollowersRequest(): Promise<
  AxiosResponse<UserDTO[], any>
> {
  return await $api.get(`/profile/my/followers`);
}

export async function fetchMyFollowingRequest(): Promise<
  AxiosResponse<UserDTO[], any>
> {
  return await $api.get(`/profile/my/following`);
}

export async function fetchFollowersByIdRequest(
  id: string
): Promise<AxiosResponse<UserDTO[], any>> {
  return await $api.get(`/profile/${id}/followers`);
}

export async function fetchFollowingByIdRequest(
  id: string
): Promise<AxiosResponse<UserDTO[], any>> {
  return await $api.get(`/profile/${id}/following`);
}

export async function fetchEventsByUserIdRequest(
  params: EventParams
): Promise<AxiosResponse<EventsResponseExtend, any>> {
  const { userId, ...rest } = params;
  return await $api.get(
    getQueriedUrl({
      url: `/profile/${userId}/events`,
      query: rest,
    })
  );
}
