import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { UploadFile } from "antd/lib/upload/interface";
import { UploadProps } from "antd/es/upload";
import _ from "lodash";
import { Form, Upload, FormInstance } from "antd";
import { BASE_API_URL } from "@app/helpers/http";

type ImagesFieldProps = {
  form: FormInstance;
  initValue?: any;
  handleRemove?: (file: UploadFile) => void;
  maxImages?: number;
};

// const getBase64 = (
//     file: RcFile,
//     callback: (url: string) => void
//   ): Promise<string> =>
//     new Promise((resolve, reject) => {
//       const reader = new FileReader();
//       reader.readAsDataURL(file);
//       reader.addEventListener("load", () => callback(reader.result as string));
//     });
// const beforeUpload = (file: RcFile) => {
//     const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
//     if (!isJpgOrPng) {
//       message.error("You can only upload JPG/PNG file!");
//     }
//     const isLt2M = file.size / 1024 / 1024 < 2;
//     if (!isLt2M) {
//       message.error("Image must smaller than 2MB!");
//     }
//     return isJpgOrPng && isLt2M;
//   };

const getFilePrepared = (fileList: any[]): UploadFile[] =>
  fileList?.map((image: any, index: number) => {
    return {
      uid: image._id,
      name: image.name,
      status: "done",
      url: `${BASE_API_URL}images/${image.path}`,
    };
  });

const ImagesFileld: React.FC<ImagesFieldProps> = ({
  form,
  initValue,
  handleRemove,
  maxImages
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const filePrepared = getFilePrepared(initValue);
  const [fileList, setFileList] = useState<UploadFile[]>(filePrepared || []);

  const maxNumImages = maxImages ? maxImages : 3;

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>{t("common.uploadBtn")}</div>
    </div>
  );

  const imagesRule = {
    validator($: any, value: any) {
      if (!_.isEmpty(fileList)) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error(t("common.validation.uploadPhoto") as string)
      );
    },
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  useEffect(() => {
    form.setFieldsValue({ imageUploades: fileList });
  }, [fileList, form]);

  return (
    <Form.Item
      name="imageUploades"
      rules={[imagesRule]}
      initialValue={initValue}
    >
      <Upload
        listType="picture-card"
        fileList={fileList}
        onChange={handleChange}
        onRemove={handleRemove}
      >
        {fileList.length >= maxNumImages ? null : uploadButton}
      </Upload>
    </Form.Item>
  );
};
export default ImagesFileld;
