import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { Badge, Empty } from "antd";
import { ChatContext } from "@app/store/provider";
import * as _ from "lodash";
import { textRefactor } from "@app/helpers/utils/common";
import { getUserAvatar, getUserFullName } from "@app/helpers/utils/user";
import { getLocalUserId } from "@app/helpers/jwtHelper";
import { getDateShow, getTimeAgo } from "@app/helpers/utils/date";
import { OnlineUsers } from "@app/store/mobx/socket";
import modals from "@app/store/mobx/modals";
import { useTranslation } from "react-i18next";

type Props = {
  onlineUsers: OnlineUsers[];
};

const PrivateChat: FC<Props> = ({ onlineUsers }) => {
  const { t } = useTranslation();
  const { getPrivateChats } = React.useContext(ChatContext);
  const privateChats: any[] = getPrivateChats();
  const myId = getLocalUserId();

  const screenSize = document.documentElement.scrollWidth;

  const getLatestMessage = (latestMessage: any) =>
    latestMessage ? latestMessage.content : t("chat.noMessages");
  const autorMessageName = (latestMessage: any) =>
    latestMessage ? getUserFullName(latestMessage.sender) + ": " : "";

  const actionType = screenSize > 991 ? () => null : modals.onVisibleChatDrawer;

  return (
    <ul>
      {!_.isEmpty(privateChats) ? (
        privateChats.map((chat: any, key: number) => {
          const _id = chat._id;
          const latestMessage = chat?.latestMessage;
          const penPal = _.chain(chat.users)
            .filter((user) => user._id !== myId)
            .head()
            .value();
          const isOnline = onlineUsers
            .map((user) => user.userId)
            .includes(penPal?._id);

          const isToday = moment().isSame(chat.updatedAt, "day");
          const unread = chat?.unread?.count;

          return (
            <>
              <NavLink key={key} to={`${_id}`} onClick={actionType}>
                <li className="user-list-item">
                  <div className="user-list-item__wrapper">
                    <div className="avatar">
                      <img src={getUserAvatar(penPal)} alt="image" />
                      <div className="badge-direction-bottom">
                        <span
                          className={`${
                            isOnline ? "avatar-online" : "avatar-offline"
                          }`}
                        ></span>
                      </div>
                    </div>
                    <div className="users-list-body">
                      <div className="users-list-body-title">
                        <h6>{getUserFullName(penPal)}</h6>
                        <div className="text-limit">
                          <p className="mb-0">
                            {autorMessageName(latestMessage)}
                            {textRefactor(getLatestMessage(latestMessage), 25)}
                          </p>
                        </div>
                      </div>
                      <div className="last-chat-time unread">
                        <small>
                          {isToday
                            ? getTimeAgo(chat.updatedAt)
                            : getDateShow(chat.updatedAt)}
                        </small>
                        {unread && (
                          <Badge
                            count={unread}
                            style={{ backgroundColor: "#52c41a" }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </li>
              </NavLink>
            </>
          );
        })
      ) : (
        <div className="empty-content">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      )}
    </ul>
  );
};

export default PrivateChat;
